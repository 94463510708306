/*
 Font size utilities following TailwindCSS convention
 see https://tailwindcss.com/docs/font-size
*/
.text-14
  font-size: 14px

.text-16
  font-size: 16px

.text-18
  font-size: 18px

.text-20
  font-size: 20px

.text-24
  font-size: 24px
