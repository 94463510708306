//.tel-input





/*
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag 
  display inline-block
  width 24px
  height 24px
  margin-bottom -5px


.flag-au 
    background-position -24px 0

.flag-gb 
    background-position -48px 0

.flag-hk 
    background-position 0 -24px

.flag-nz 
    background-position -24px -24px

.flag-sg 
    background-position -48px -24px

.flag-us 
    background-position 0 -48px
