.xml-feeds-section
  &__title
    display flex
    justify-content space-between
    align-items flex-start
    padding 30px
    flex-direction column
  &--title
    margin: 0
    font-style: normal
    font-weight: normal
    font-size: 24px
    line-height: 28px
    color: $silver
  &--content
    margin-top 20px
    margin-bottom 20px
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    color: $silverSecond
  &__item
    display flex
    align-items center
    width: 100%
    min-width 720px
    height 48px
    border-bottom 1px solid #ECEFF1
    font-size: 16px
    line-height: 24px
    color $silver
    &:last-child
      border-bottom none
  &--item-name
    width 20%
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  &--item-link
    width 60%
  &--item-action
    width 20%
    display flex
    justify-content flex-end
    align-items center
  &__add-form
    display flex
    align-items flex-end
    width 100%
  &--add-xml-input
    width 100%
    max-width 190px
    margin-right 24px
  &__content
    overflow-y auto
    width: 100%
  &__button-wrapper
    display flex
    align-items flex-end
    margin-bottom 3px
.refari-copied-wrap
    position relative
    .refari-copied-msg
      position: absolute
      right: -50px
      top: 12px
