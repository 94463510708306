.reports
    width 100%
    margin-bottom 48px
    .reports-container
        margin-bottom 5px

.report-blur-image
    background: url("../../assets/images/review-blur-image.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: calc(100% + 48px);
    min-height: 600px;

.rating-blur-image
    background: url("../../assets/images/rating-blur-image.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 600px;

  .ai-budget-blur-image
    background: url("../../assets/images/ai-budget-blr-image.webp") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 600px;

  .talent-alert-blur-image
    background: url("../../assets/images/talent-alert-blur-image.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 600px;
            
.consultant-box
    position: relative;
    display: flex;
    width: 100%;
    background-color: #F8F8F8;
    height: 100%;

.consultant-boxes
    max-height: 850px;
    overflow-y: scroll;
    padding-right: 20px;
    .pagination-wrap
        margin-top: 10px;
        .MuiPaginationItem-root
            width: 20px;
            min-width: 20px;
            height: 20px;

.recruiter-header h5
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #3886D1;
    margin: 0;
    line-height: 30px;
    text-transform: uppercase;

.recruiter-box
    display: block;
    width: 255px;
    padding: 20px;
    padding-left: 0;
    padding-right: 0;
    background-color: #fff;
    box-shadow: 14px 0px 15px rgba(234, 234, 234, 0.2);

.select-recruiter-button
    display: none;
    width: 8%;
    .MuiButton-startIcon
        margin-right: 0

.recruiter-input
    position: relative;
    display: block;
    width: 100%;
    background-color: #F9F9F9;
    border: 0.5px solid #D9D9D9;
    font-size: 12px;
    font-weight: 300;
    line-height: 30px;
    color: #9F9FA1;
    letter-spacing: 0.3px;
    padding: 2px 15px;
    padding-left: 30px;
    border-radius: 2px;
    transition: 0.5s all ease;

.search-box
    position: relative;
    display: block;
    width: 100%;

.search-icon
    position: absolute;
    display: block;
    width: fit-content;
    top: 6px;
    left: 5px;
    color: #9F9FA1;

.found-box
    position: relative;
    width: 100%;
    align-items: center;
    margin: 5px 0;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 20px;

.recruiter-header
    padding-left: 15px;
    padding-right: 20px;

.found-title h5
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #3886D1;
    margin: 0;

.found-title
    display: block;
    width: 100%;
    min-height: 30px;

.found-link
    display: block;
    width: 100%;

.found-link a
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #757575;
    border: 0.2px solid #404144;
    padding: 4px 10px;
    text-decoration: none;

.found-link .dropdown-item
    border: none;
    font-size: 14px;


.conslt-box
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 6px 0;
    border-left: 3px solid transparent;
    padding-left: 20px;
    border-bottom: 0.5px solid #D9D9D9;
    text-decoration: none;
    transition: 0.5s all ease;

.consultant-cont img
    border-radius: 50%;
    margin-right: 10px;
    width: 40px;
    height: 40px;

.consultant-cont, .graph-tab-header
    .img-fluid-circle
        border-radius: 50%;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        display: inline-block;
        background-color: #ccc;
        vertical-align: middle;
        border-style: none;

.consultant-cont
    display: block;
    width: 85%;

.consultant-arrow
    display: block;
    width: 15%;
    text-align: right;

.consultant-cont
    display: block;
    width: 85%;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #2E364B;

.conslt-box:hover, .conslt-box.active
    background: transparent linear-gradient(90deg, #A2D2FF57 0%, #FFFFFF00 71%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    border-left: 3px solid #3886d1;
    text-decoration: none;
    transition: 0.5s all ease;

.consultant-area
    position: relative;
    display: block;
    width: calc(100% - 255px);

.dashboard-graph-tab
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #ECEFF1;
    padding: 10px 30px;
    height: 61px;

.graph-tab-header
    display: block;
    width: 25%;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #2E364B;

.graph-tab
    display: block;
    width: 75%;

.dashboard-graph
    position: relative;
    display: block;
    width: 85%;
    background-color: #fff;
    margin: 20px;
    box-shadow: 0 0 15px rgba(234, 234, 234, 0.6);

.graph-tab-header img
    border-radius: 50%;
    margin-right: 15px;
    width: 40px;
    height: 40px;

.graph-tab .nav
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

.graph-tab ul li span.nav-link
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.28px;
    color: #9F9FA1;
    padding: 0;
    margin: 0 16px;

.graph-tab ul li span.nav-link.active
    background-color: transparent;
    color: #3886D1;

.graph-tab ul li span.nav-link.active:after
    position: absolute;
    display: block;
    content: '';
    border-top: 1px solid #3886d1;
    width: 100%;
    bottom: 0;
    left: 0;

.graph-area 
    min-height: 450px

.graph-tab ul li.nav-item
    position: relative;
    padding: 15px 0px;
    cursor: pointer

.chart-box
    width: 75%;
    display: block;
    position: relative;
    margin: 10px auto 0 auto;

.avg-rating-star ul li
    position: relative;
    display: inline-block;
    color: #ffbf00;

.avg-rating-star ul
    list-style: none;
    margin: 0;

.rate-cont
    display: block;
    width: 50%;
    align-self: flex-end;

.avg-rating-star
    display: block;

.avg-rating-box
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;

.rating-link
    display: flex;
    width: 30%;
    text-align: right;
    justify-content: flex-end;
    &-left
        margin-right: 40px;
        text-align: left;

.filter-date-picker-block
    width: 100%;
    display: flex;
    align-items: center;

.custom-date-picker .filter-date-picker
    width: 45%;
    overflow: hidden;
    margin-left: 5%;

.filter-date-button
    margin-top: 5px



.rating-cont
    display: block;
    width: 70%;
    align-self: flex-start;

.rating-box
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 30px;

.avg-rating-star h5
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #949496;
    margin-bottom: 0;

.avg-rating-star .widget-ratings
    margin-top: 3px;

.rate-cont p
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    color: #35A28A;
    letter-spacing: 0.5px;

.rating-link a
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #757575;
    border: 0.2px solid #404144;
    padding: 4px 10px;
    text-decoration: none;

.rating-link .dropdown
    display: inline-block;
    min-width: 140px;
    text-align: left;

.avg-rat p
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    color: #9F9FA1;
    letter-spacing: 0.5px;

.avg-rat
    position: absolute;
    display: block;
    width: fit-content;
    top: 50%;
    left: 3%;
    transform: rotateZ(-91deg);

.avg-rat.Months
    position: relative;
    display: block;
    width: 100%;
    transform: rotateZ(0deg);
    text-align: center;
    padding-bottom: 30px;
    margin-top: 10px;

.rat-dot
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 4px solid #6BB129;
    margin: 0 auto;
    background: #fff;

.rating-dot
    position: absolute;
    display: none;
    width: fit-content;
    text-align: center;
    top: -45px;
    left: 50%;

.progress:hover .rating-dot {
    display: block;
}

.rat-flag p
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
    color: #404144;

.rat-flag p span
    font-size: 12px;
    font-weight: 400;
    color: #9F9FA1;

.rat-flag
    position: relative;
    display: block;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 0 15px #6969693b;
    border-radius: 4px;

.rat-flag:after
    position: absolute;
    display: block;
    content: '';
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-top: 15px solid #ffffff;
    left: 40px;
    bottom: -23px;

.dashboard-graph-tab h5
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0;
    color: #404144;

.overview-box
    position: relative;
    display: flex;
    width: 85%;
    margin: 20px;

.avg-rating-star .star-cont
    color: #404144;
    margin-left: 10px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;

.rating-overview
    display: block;
    width: 50%;
    margin-right: 30px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(234, 234, 234, 0.6);

.cloud-review
    display: block;
    width: 50%;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(234, 234, 234, 0.6);

.rating-overview .avg-rating-box
    padding: 0 30px;
    margin-top: 10px;

.rating-overview .avg-rating-star
    width: 50%;

.rating-overview .rate-cont p
    text-align: right;

.toprec-feedback.consultant-box
    background-color: #fff;
    .dashboard-graph
        box-shadow: none
        width: calc(100% - 40px)
    .recruiter-box
        overflow: hidden;
    .consultant-boxes
        max-height: inherit;
        height: 100%;
        overflow-y: auto;
    .select-recruiter-button
        width: inherit;






/* -------------------------------
progess bar
------------------------------ */

.progress-title
    font-size: 16px;
    font-weight: 700;
    color: #333;
    margin: 0 0 20px;

.progress
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: .75rem;
    height: 10px;
    background: #EEEFF3;
    border-radius: 12px;
    box-shadow: none;
    margin-bottom: 0px;
    overflow: visible;
    width: 70%;

.progress .progress-bar
    position: relative;
    -webkit-animation: animate-positive 2s;
    animation: animate-positive 2s;
    border-radius: 12px 0 0 12px;

.progress .progress-value
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    position: relative;

.progressbar
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 30px;
    align-items: center;

.progress-value
    display: block;
    width: 15%;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: #9F9FA1;

.progressbar-box
    position: relative;
    display: block;
    width: 100%;
    padding: 15px 0 30px 0;

@-webkit-keyframes animate-positive{
    0%{width: 0}
}

@keyframes animate-positive{
    0%{width: 0}
}


.anychart-credits
    display: none !important;

.cloud-box
    position: relative;
    display: block;
    width: 100%;
    height: 80%

.toprec-box
    position: relative;
    display: block;
    width: 100%;
    padding: 30px 60px;
    text-align: center;

.toprec-cont h5
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #2E364B;
    letter-spacing: 0.5px;
    margin-bottom: 5px;

.toprec-cont p
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    color: #9F9FA1;
    letter-spacing: 0.20px;

.toprec .modal-header
    padding: 0;
    border: none;

.recruiter-header-close
    display: none
    .MuiButton-startIcon
        margin-right: 0

.toprec
    background-color: rgba(56, 134, 209, 0.2);
    backdrop-filter: blur(8px);
    width: 86%;
    margin-left: auto;

.nav-pills-dropdown
    display: none

@media screen and (max-width: 1330px)
    .graph-tab-header
        width: 30%;
    .graph-tab
        width: 70%;
    .graph-tab ul li span.nav-link
        margin: 0 12px;

@media screen and (max-width: 1190px)
    .graph-tab-header
        width: 35%;
    .graph-tab
        width: 65%;

@media screen and (max-width: 1295px)
    .avg-rat
        left: 0
    .overview-box
        display: block;
    .rating-overview
        width: 100%
    .cloud-review
        width: 100%
        margin-top: 20px;
    .graph-tab ul li span.nav-link
        margin: 0 5px;

@media screen and (max-width: 1200px)
    .recruiter-box
        display: none;
        position: absolute;
        top: 0
        z-index: 1
        height: 100%;
        padding-top: 10px;
    .graph-tab-header
        width: 30%;
    .graph-tab ul li span.nav-link
        margin: 0 8px;
    .recruiter-header-close
        display: block
        text-align: right;
        margin-right: 10px;
    .select-recruiter-button
        display: block;
    .graph-tab
        width: 62%;
    .consultant-area
        width: 100%;
    .dashboard-graph
        width: calc(100% - 48px);
        margin: 24px;
    .overview-box
        width: calc(100% - 48px);
        margin: 24px;

@media screen and (max-width: 602px)
    .select-recruiter-button
        width: 52px
    .graph-tab-header
        width: calc(100% - 237px);
    .graph-tab
        width: 185px;
    .graph-tab .nav
        display: none
    .graph-tab .nav-pills-dropdown
        display: flex
    .avg-rat
        left: -15px;

@media screen and (max-width: 530px)
    .graph-tab-header
        display: none

@media screen and (max-width: 768px)
    .select-recruiter-button
        width: 52px
    .graph-tab-header
        width: calc(100% - 237px);
    .graph-tab
        width: 185px;
    .graph-tab .nav
        display: none
    .graph-tab .nav-pills-dropdown
        display: flex
    .avg-rat
        left: -15px;
    .avg-rating-box, .avg-rating-star, .rate-cont, .rating-overview .avg-rating-star
        display: block;
        width: 100%
    .rating-overview .rate-cont p
        text-align: left;
        

@media screen and (max-width: 600px)
    .graph-tab-header
        display: none
    .rating-box, .rating-cont, .avg-rating-box, .avg-rating-star, .rate-cont, .rating-overview .avg-rating-star, .rating-link .dropdown, .rating-link
        display: block
        width: 100%;
    .avg-rat
        left: -40px;
        top: 65%;
    .rating-overview .rate-cont p
        text-align: left;
    .progress-value
        width: 25%;
    .dashboard-graph-tab h5
        font-size: 15px;
    .graph-area
        min-height: 352px;
    .chart-box
        margin: 35px auto 0 auto;
    .dashboard-graph-tab
        padding: 10px 15px;


    