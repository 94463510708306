
logo-height = 60px
$header--sales-bg-color = #f9fbfe
.header__user-name-wrapper
  position relative
  color: #fff !important
  font-size: 18px !important
  padding 20px 24px 0 16px
.header__user-name
  overflow hidden
  text-overflow ellipsis
  width 100%
  line-height: 28px !important
.header
  padding 10px 0
  @media screen and (max-width 600px)
    display: flex;
    align-items: center;
  .header-space
    @media screen and (max-width 600px)
      width: 100%
  .hidden-xs
    display block !important
  @media screen and (max-width 768px)
    line-height initial !important
  .brand-logo-section
    width: 63%
    display: flex
    justify-content: flex-end
    &--button
      display none !important
    @media screen and (max-width 991px)
      align-items: center
      justify-content: space-between
      &--button
        display block !important
  .brand-logo
    max-width 425px
    max-height 85px
    display block !important
    @media screen and (max-width 600px)
      max-width 200px
      max-height: 75px
    @media screen and (max-width 440px)
      max-width 150px
      max-height: 65px
    @media screen and (max-width 359px)
      max-width: 120px;
      max-height: 55px
  .logo-height
    height logo-height
    @media screen and (max-width 768px)
      height logo-height - 20
  .logo-right
    display flex
    justify-content: flex-end
    width 37%
  .logo
    a
      display inline-block
    img
      height logo-height
      @media screen and (max-width 768px)
        height logo-height - 20
    .logo-link
      display flex
      align-items center
      text-decoration none
      &:hover
        text-decoration none
      svg
        @media screen and (max-width 600px)
          width: 70px !important;
        @media screen and (max-width 440px)
          width: 50px !important;
        @media screen and (max-width 359px)
          width: 40px !important;
    .logo-link-text
      margin-right 8px
      margin-top 20px
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 24px
      color: $silverSecond
      @media screen and (max-width 600px)
        font-size 9px
        margin-top: 12px;
      @media screen and (max-width 440px)
        font-size: 8px;
        margin-top: 8px;
        margin-right: 5px;
    .logo-link-image
      width 45px
      height 45px
  .header-drawer
    ::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
      border-radius: 5px
      background-color: #F5F5F5
    ::-webkit-scrollbar
      width: 6px
      background-color: #F5F5F5

    ::-webkit-scrollbar-thumb
      border-radius: 6px
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
      background-color: #8E8E8E
  .drawer-wrapper
    min-height 100vh
  .header__user-badge
    font-size 0.6em
    right 0
    top 4px
    line-height 1

  &.header--sales
    position fixed
    width 100%
    transform translateX(0)
    border-bottom 0
    background-color transparent
    //transition transform 0.1s cubic-bezier(0.86, 0, 0.07, 1)
    transition transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)
    background-color $header--sales-bg-color
    box-shadow 0 0px 0px 0px rgba(0,0,0,0.05)
    z-index 20
    will-change auto
    .logo,
    img
      transition height 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)
      will-change height
      @media screen and (max-width 360px)
        height logo-height - 30
    &.is-minified
      box-shadow 0 1px 15px 1px rgba(0,0,0,0.05)
      .logo,
      img
        height logo-height - 20
        @media screen and (max-width 360px)
          height logo-height - 30

.header-burger-menu
  position relative
  &:after
    content: ''
    position absolute
    right: 5px
    top: 10px
    border 4px solid #E53935
    border-radius: 50%

.header-notification-menu-0
  position relative
  &:nth-child(1)&:after
    content: ''
    position absolute
    right: 42px
    top: 10px
    border 4px solid #E53935
    border-radius: 50%
.header-notification-menu
  position relative
  &:nth-child(1)&:after
    content: ''
    position absolute
    left: 38px
    top: 10px
    border 4px solid #E53935
    border-radius: 50%

.header-border-button
  border-bottom 1px solid #3886D1

.header-space
  max-width: 1440px
  margin: 0 auto
  padding 0px 24px 0 224px

.header-nav
  height 100%
  min-height 98px
  color: #fff
  &--name
    margin 0
    padding 0
    font-size: 18px
    line-height: 24px
  &--board
    height: 57px
    margin 0
    padding: 0 10px 0 17px
    font-size: 14px
    line-height: 24px
    display: flex
    justify-content: center
    align-items: center
    align-content: center
    > div
      min-width: 170px;
  &--left-space
    padding-left: 40px !important
    transition background .3s
    cursor: pointer
  &--top-border
    border-top 1px solid #E0EFFF !important
  &__item
    .MuiListItemIcon-root
      min-width: 40px !important;
  &--logout
    padding-left 56px !important
  &--text
    color $silver !important
    .MuiListItemIcon-root
      min-width: 40px !important;
    svg
      color $silver !important
  &__item
    svg
      color $silver !important
@media screen and (max-width: 991px)
  .header-space
    padding: 0px 15px 0 3px
@media screen and (max-width: 600px)
  .extra-div-non-loggedin
    width: inherit !important

.header_height--userLoggedInAs
  height: 115px

  @media screen and (max-width: 991px)
    height: 200px
    
  @media screen and (max-width: 768px)
    height: 115px

.header_height--regular
  height: 105px
