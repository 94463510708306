@import '../../../../../styles/utils/index.styl'

/*
  custom properties

  // background color for testimonial text section
  --smartPitchTestimonialDetailsModal-testimonialTextSection-bacground-color
*/
.smartPitchTestimonialDetailsModal
  .MuiDialog-paper
    background-color: '#fff'
    padding-bottom: 20px

.smartPitchTestimonialDetailsModal-testimonialTextSection
  display: flex
  flex-direction: column
  padding: 15px
  color: #515151
  background-color: var(--smartPitchTestimonialDetailsModal-testimonialTextSection-bacground-color)

.smartPitchTestimonialDetailsModal-testimonialQuotedText
  position: relative

.smartPitchTestimonialDetailsModal-testimonialQuotedText-quotesIcon
  position: absolute
  left: -30px
  top: 0

.smartPitchTestimonialDetailsModal-testimonialQuotedText-endQuotesIcon
  position: absolute
  right: 0
  bottom: 0

.smartPitchTestimonialDetailsModal-jobtitle
  color: #50555A

.smartPitchTestimonialDetailsModal-avatarRatingsSection
  color:#515151

  .refari-avatar-wrap
    height: 95px
    width: 95px
    border: 5px solid #fff;

.smartPitchTestimonialDetailsModal-avatarRatingsSection-topMargin
  margin-top: -40px

  @media screen and (max-width: 768px) {
    margin-top: 10px
  }

.smartPitchTestimonialDetailsModal-avatarRatingsSection-fullNameAdditional
  color: #50555A

.smartPitchTestimonialDetailsModal-verificationText
  color: #515151