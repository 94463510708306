.refari-input-wrap
  .MuiInput-underline.Mui-disabled:before
    border-bottom-style: solid
  
  .MuiInputAdornment-positionEnd
    margin-left: 0

  input, textarea
    box-shadow none
    border-radius 0
    background: inherit;
    background-color: inherit;
    border: none;
    padding: 6px 0 7px;
    box-sizing: unset;
    margin: 0;
    border-style: unset;
    &:focus
      box-shadow none
      background-color: transparent
      border-radius 0
      border: none;

.refari-field-file-wrap
  margin-top 15px
  margin-bottom 5px
  .refari-file-remove
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: red;
    margin-top: 3px;
    cursor: pointer;
  .refari-file-label
    font-size 12px
    font-weight 400
    margin-bottom: 5px
    color: rgba(0, 0, 0, 0.54)
    .refari-required
      font-size: 13.5px !important;
  .refari-file-input
    cursor pointer
    position absolute
    top 0
    bottom 0
    right 0
    left 0
    width 100%
    opacity 0
    .refari-field-file-additional-text
      max-width 370px
      margin-bottom 20px
  .refari-file-btn-spinner
    position absolute !important
    top 50%
    transform translate(-50%, -50%)
    left 50%
  .refari-file-wrap
    display flex
    align-items baseline
    .refari-file-btn
      margin-right 10px
      flex-grow 0
      position relative
    .refari-file-preview-wrap
      flex-grow 1
      .refari-upload-file-wrap-tick
        position: relative;
        input
          padding-right: 35px !important;
        .refari-file-preview-right-block
          position: absolute;
          right: 0px;
          top: 14px;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 30px;
          &:before
            position: absolute;
            content: "";
            height: 23px;
            width: 1px;
            background-color: rgba(0, 191, 97, 0.25);
            left: 0px;
            top: 0px;
            bottom: 0px;
            transform-origin: center center;
            margin: auto 0px;
            animation: 0.2s ease 0s 1 normal none running eqFady;
          .refari-file-preview-right-icon
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: 8s ease 0s 1 normal forwards running ffGLem;
        .refari-file-preview-right-block-error
          &:before
            background-color: rgba(255, 0, 0, 0.25);
    &.refari-image
      align-items flex-end
      .refari-file-btn-wrap
        order 2
        margin-right 0
        margin-left 30px
        flex 1 0 0
        .refari-file-btn
          max-width 200px
          width 100%
      .refari-file-preview-wrap
        order 1
        .refari-upload-image-wrap
          width 140px
          height 140px
          display flex
          justify-content center
          align-items center
          img
            width 100%
    .refari-upload-file-wrap
      input::placeholder 
        font-size 1em

@media (min-width 1000px) and (max-width 1130px)
  .refari-field-file-wrap
    .refari-file-wrap
      .refari-upload-file-wrap
        input::placeholder 
          font-size 0.8em

@media (min-width 792px) and (max-width 1000px)
  .refari-field-file-wrap
    .refari-file-wrap
      .refari-upload-file-wrap
        input::placeholder 
          font-size 0.6em

@media (max-width 400px)
  .refari-field-file-wrap
    .refari-file-wrap
      .refari-upload-file-wrap
        input::placeholder 
          font-size 0.8em

.refari-input-wrap-tick
  position: relative;
  input, textarea
    padding-right: 35px !important;
  .right-input-section-block
    position: absolute;
    right: 0px;
    top: 45px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 30px;
    &:before
      position: absolute;
      content: "";
      height: 23px;
      width: 1px;
      background-color: rgba(0, 191, 97, 0.25);
      left: 0px;
      top: 0px;
      bottom: 0px;
      transform-origin: center center;
      margin: auto 0px;
      animation: 0.2s ease 0s 1 normal none running eqFady;
    .right-input-icon
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 6px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: 8s ease 0s 1 normal forwards running ffGLem;
  .right-input-section-block-error
    &:before
      background-color: rgba(255, 0, 0, 0.25);


.field-file-wrap
  margin-top 15px
  margin-bottom 5px
  &.wide-preview
    .file-wrap.image
      .file-preview-wrap
        .upload-image-wrap
          width 300px
          height 100px
          border-radius 0%
          img
            border-radius 0%
            max-width 100%
            max-height 100%
            width auto
  .file-label
    font-size 12px
    font-weight 500
    margin-bottom 15px
  .file-input
    cursor pointer
    position absolute
    top 0
    bottom 0
    right 0
    left 0
    width 100%
    opacity 0
    .field-file-additional-text
      max-width 370px
      margin-bottom 20px
  .file-wrap
    display flex
    align-items baseline
    flex-wrap wrap
    .file-btn
      margin-right 10px
    &.image
      align-items: start;
      .file-btn-wrap
        order 2
        margin-right 0
        margin-left 30px
        flex 1 0 0
        .file-btn
          max-width 200px
          width 100%
      .file-preview-wrap
        order 1
        .upload-image-wrap
          width 140px
          height 140px
          display flex
          justify-content center
          align-items center
          border 1px solid #a2c2e5
          border-radius 50%
          img
            border-radius 50%
            width 100%
    .field-file-additional-text
      margin-bottom 25px

.refari-field-tags-wrap
  max-width 100%

.refari-field-tags-wrap-new
  .react-tagsinput
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 10px 0px;
    > span
      align-items: inherit !important;
.refari-field-tags-wrap-email
  margin-top 15px
  label
    font-size: 12px;
.refari-field-tags-wrap-floating
  .react-tagsinput
    position: relative
    .refari-react-tagsinput-tag-wrap
      &:first-child
        margin-top: 8px
  .refari-react-tagsinput-input-floating-label
    position: absolute;
    line-height: 22px;
    font-weight: 400;
    font-size: 16px;
    top: 6px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1;
    transform: scale(1) translate(0px, 0px);
    transform-origin: left top;
    pointer-events: none;
    user-select: none;
    color: rgba(74, 74, 74, 0.8);

.submit-btn
  margin-top 25px !important

.required
  top 0
  font-size 18px

.mandatory
  top 0
  font-size 12px

.non-field-errors
  font-size 12px

.inline-form
  display flex
  align-items center
  .main-inline-field
    flex 1 0 0
  .side-inline-field
    margin-left 30px
    min-width 120px

.react-tagsinput
  max-width 100%
  & > span
    flex-wrap wrap
    display flex
    align-items baseline
  .refari-react-tagsinput-tag-wrap
    margin-right 5px
    margin-bottom 5px
    max-width 100%
    display: flex;
    svg
      color: #fff
    .refari-react-tagsinput-tag
      max-width 100%
      & > span
        text-overflow ellipsis
        overflow hidden
      & > svg
        flex 1 0 24px
    .refari-react-tagsinput-between-op
      display: flex;
      margin-left: 10px;
      margin-right: 5px;
      font-family: inherit;
      text-decoration: none;
      color: #6DB9FF;
      padding: 0px;
      outline: none;
      font-size: 12px;
      font-weight: inherit;
      align-items: center;
    .refari-react-tagsinput-between-op-or
      color: rgb(245, 166, 35);
  .refari-react-tagsinput-tag-wrap-new
    margin-bottom: 10px;
    .MuiChip-root
      height: inherit;
    .react-tagsinput-tag
      border-radius: 5px !important
      padding: 3px !important
      span
        padding-right: 20px !important;
        line-height: 36px !important;
      svg
        width: 20px !important;
        color: #fff
  .refari-react-tagsinput-tag-add-button
    margin-left: 5px;
    margin-bottom: 10px;
    max-width: 100%;
    display: flex;
    .add-tag-button
      padding: 0 !important
      width: 40px !important
      height: 40px !important
      svg
        height: 35px !important
        width: 35px !important

  .refari-react-tagsinput-input
    flex 1 0 0
    min-width 200px
    .MuiFormControl-fullWidth
      margin: 0
  .refari-react-tagsinput-input-tick
    position: relative
    input
      padding-right: 35px !important;
    .refari-react-tagsinput-input-right-block
      position: absolute;
      right: 0px;
      top: 15px;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 30px;
      &:before
        position: absolute;
        content: "";
        height: 23px;
        width: 1px;
        background-color: rgba(0, 191, 97, 0.25);
        left: 0px;
        top: 0px;
        bottom: 0px;
        transform-origin: center center;
        margin: auto 0px;
        animation: 0.2s ease 0s 1 normal none running eqFady;
      .refari-react-tagsinput-input-right-icon
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: 8s ease 0s 1 normal forwards running ffGLem;
  .refari-react-tagsinput-input-new
    min-width 100%
    width 100%
    margin-bottom 10px
    .MuiFormControl-fullWidth
      margin-bottom: 5px !important;
      margin-top: 15px !important; 
  .refari-react-tags-select-input-new
    margin-bottom 10px
  .refari-react-tags-select-input
    position relative
    flex 1 0 0
    min-width 100%
    width 100%
    &__modal
      position absolute
      left 0
      top 45px
      width 100%
      height auto
      max-height 167px
      overflow-y scroll !important
      z-index 1
      background-color #fff
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      .refari-filter-item
        padding  0px 56px 0px 20px !important
      .refari-nested
        padding-left 40px !important
    &--empty-modal
      padding 10px
      text-align 10px
      font-size 14px

.refari-google-search-location
    flex 1 0 0
    min-width 200px
    .MuiFormControl-fullWidth
      margin: 0
  .refari-google-search-location-error
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #f44336
  .refari-google-search-location-tick
    position: relative
    input
      padding-right: 35px !important;
    .refari-google-search-location-right-block
      position: absolute;
      right: 0px;
      top: 18px;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 30px;
      &:before
        position: absolute;
        content: "";
        height: 23px;
        width: 1px;
        background-color: rgba(0, 191, 97, 0.25);
        left: 0px;
        top: 0px;
        bottom: 0px;
        transform-origin: center center;
        margin: auto 0px;
        animation: 0.2s ease 0s 1 normal none running eqFady;
      .refari-google-search-location-right-icon
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: 8s ease 0s 1 normal forwards running ffGLem;

.refari-textarea-no-rows
  textarea
    min-height: 50px

.refari-job-alert-template
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 30px;

  button.refari-button-raised
    margin-top: 15px;
.refari-message-template-modal
  max-width: 500px !important;

.m-right_1x
  margin-right 10px
.m_r_1px
  margin-right 14px !important

.consultant-tags
  flex-wrap wrap
  display flex
  .consultant-tag
    margin-top 2px !important
    margin-right 5px !important
    margin-bottom 5px !important
    & > span
      text-overflow ellipsis
      max-width 680px
      overflow hidden


.images-list
  label
    margin-top 20px
  .images-list__additional-text
    margin-bottom 30px
  ul
    list-style none
    padding 0
    margin 0
  .images-list__img-wrapper
    width: 250px;
    height: 131px;
    img
      display block
      max-width: 250px;
      width 250px
  .field-file-wrap .file-wrap.image .file-btn-wrap
    margin-left 0
    .file-btn 
      max-width 140px
  .square-preview
    margin-top: 0px;
    margin-bottom: 1rem;

.refari-subscribe-tab-content
  div
    overflow visible !important
  .separtor-section
    display: flex;
    margin-top: 25px;
    margin-bottom: -5px;
    margin-left: -40px;
    align-items: center;
    hr
      border-style: dashed none;
      border-color: #ccc;
      border-top: .5px #ccc dashed;
      margin: 0;
      padding: 0;
      &.left-line
        width: 35px;
      &.right-line
        width: calc(100% - 100px)
    label
      width: 65px;
      text-align: center;
      color: #6DB9FF;
      margin: 0;
      font-size: 14px;
      padding: 0;

  &__center-button
    display flex
    justify-content center
    padding-top 20px
    .refari-submit-btn
      margin-top 0 !important
      margin-right 12px

.refari-subscribe-link-modal
  .separtor-section
    margin-left: 0px !important;

.cke_top
  display: none !important
.cke_bottom
  display: none !important
.cke_contents
  height: 100px !important
.cke
  border: 1px solid #0000006b !important;

.refari-input-editor-wrap
  margin-top: 15px;
  .MuiTypography-root
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
  .public-DraftEditor-content
    min-height: 70px;

.refari-submit-btn
  white-space nowrap
  margin-top 25px !important
  [type="submit"]
    text-shadow none
    &:focus, &:hover
      text-shadow none
  button
    div
      div
        span
          padding: 10px !important

@media screen and (max-width 800px)
  .refari-subscribe-wrap
    form
      display: flex
    .refari-input-wrap
      width: 70% !important
    .refari-submit-btn
      margin-left: 24px !important
      margin-top: 28px !important
      height: 100% !important
      width: 30% !important
      min-width: 112px !important
      white-space nowrap
      button
        div
          div
            span
              padding: 0 !important

@media screen and (max-width 460px)
  .file-wrap
    flex-direction column
  .field-file-wrap
    .file-btn-wrap
      margin-left 0 !important
      margin-top 10px

.backgrounds-group-container
  .images-list__additional-text
    max-width 600px
  .images-list__loader-wrapper
    display block
    text-align center
    width 100%
    max-width 35%
    @media screen and (max-width 825px)
      max-width 100%
  .backgrounds-list
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    li
      @extend .align-items-center
      margin-bottom 25px
    .images-list__img-wrapper
      flex 1
      margin-right 15px
      position: relative;


.color-picker-wrap
  @extend .align-items-center
  position relative
  margin-top: 10px;
  .color-picker__swatch
    padding 5px
    background #fff
    border-radius 1px
    border 1px solid rgba(0,0,0,.1)
    display inline-block
    cursor pointer
  .color-picker__swatch-inner
    width 36px
    height 14px
    border-radius 2px
  .color-picker__popover
    position absolute
    top 100%
  .color-picker__picker-widget
    z-index 2
    position relative
  .color-picker__backdrop
    position fixed
    top 0px
    right 0px
    bottom 0px
    left 0px
    z-index 1
  .color-picker__label
    max-width 130px
    display inline-block
    margin-top 0
    line-height: 38px;

.refari-select-white
  svg
    fill: #fff;

.position-relative
  position: relative;

.refari-input--nofullWidthMargin
  .MuiFormControl-root.MuiFormControl-fullWidth
    margin: 0

.refari-input--disableLabelTransition
  .MuiTextField-root
    .MuiInputLabel-formControl
      transform: none !important
      transition: none !important
      top: -22px

.refari-select-wrap
  position: relative;
  .MuiSelect-select.Mui-disabled
    background-color: #f1f5f9

.right-select-section-block
    position: absolute;
    right: 12px;
    top: 24px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 30px;

.right-input-section-block-error
    &:before
      background-color: rgba(255, 0, 0, 0.25);
