/*
 Margin utilities following TailwindCSS convention
 see https://tailwindcss.com/docs/margin
*/

// shorthand
.m-0
  margin: 0

// top
.mt-10
  margin-top: 10px

.mt-15
  margin-top: 15px

.mt-20
  margin-top: 20px

.mt-40
  margin-top: 40px

// bottom
.mb-5
  margin-bottom: 5px

.mb-10
  margin-bottom: 10px

.mb-20
  margin-bottom: 20px

.mb-40
  margin-bottom: 40px

// right
.mr-5
  margin-right: 5px

.mr-15
  margin-right: 15px

.mr-40
  margin-right: 40px

// left
.ml-40
  margin-left: 40px
