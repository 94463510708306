.tabs-container
  width 100%

  position relative
  .tabs-wrap
    box-shadow 0 2px 5px rgba(0, 0, 0, 0.13), 0 1px 2px rgba(0, 0, 0, 0.12)
    margin-bottom 15px
  
  .container
    padding: 0 !important
  .tabs-inner-wrapper
    overflow-x auto
    -webkit-overflow-scrolling touch
    button
      > div
        background-color #fff
    &.with-scroll-indicator:after
      content ''
      display block
      position absolute
      right 0
      top 0
      bottom 0
      width 20px
      background linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 75%)
      background-repeat no-repeat
      z-index 1
      

@media screen and (min-width 768px)
  .tabs-container
    .tabs
      div
        button
          transition background-color 0.3s ease-in-out
        button:hover
          background-color rgb(245, 245, 245) !important

@media screen and (max-width 768px)
  .tabs-container
    .tabs-wrap
        .tabs
          max-width 1200px !important
          div:nth-child(2)
            width 100% !important
              div
          div
            button
              margin-right: 10px !important
              margin-left: 10px !important
