.home-page-section
  width 100%
  .home
    width 100%
    min-height 800px
    height 100%
    transition background-color 0.4s ease-in-out
    .home-background
      div
        background -webkit-radial-gradient(circle, #0074BC, transparent)
        background -ms-radial-gradient(circle, #0074BC, transparent)
        background -o-radial-gradient(circle, #0074BC, transparent)
        background radial-gradient(circle, #0074BC, transparent)

        @supports (-webkit-filter:hue-rotate(360deg)) {
          background -webkit-radial-gradient(circle, $teal, transparent)
          background -ms-radial-gradient(circle, $teal, transparent)
          background -o-radial-gradient(circle, $teal, transparent)
          background radial-gradient(circle, $teal, transparent)
        }

        @supports (filter: hue-rotate(360deg)) {
          background -webkit-radial-gradient(circle, $teal, transparent)
          background -ms-radial-gradient(circle, $teal, transparent)
          background -o-radial-gradient(circle, $teal, transparent)
          background radial-gradient(circle, $teal, transparent)
        }

    .home-successful-img
      margin-right 15px
    .home-block
      display flex
      flex-direction column
      align-items center
      width 100%
      h1
        margin-bottom 35px
      h3
        font-weight 300
        margin-bottom 35px
      h1,
      h3
        position relative

  &--header
    transform translate(0, 30vh)
    transition all 1s
    opacity 0
    color: #FFFFFF
    font-size: 40px;
    font-weight: 400;
    line-height: 54px
    text-align: center
  &--header-content
    margin-bottom 40px
    width 600px
    transform translate(0, 30vh)
    transition all 1s
    opacity 0
    color #FFFFFF
    font-size 24px
    line-height 40px
    text-align center
  &__content
    background-color #fff
  &__content-second
    margin-top -1px
  &__integration-placements
    display flex
    align-items center
    justify-content center
    flex-wrap wrap
    transform translate(0, 0)
    transition all 1s
    opacity 0
  &__placements-integration-section
    width: 100%
    max-width: 470px
    margin 0 15px
    padding 47px 36px
    border-radius 6px !important
    transition all 1s
  &--integration-placements-header
    margin-top 24px
    margin-bottom 16px
    color #24253D
    font-size 24px
    font-weight 500
    line-height 40px
  &--integration-placements-content
    max-width 298px
    margin-bottom 30px
    color #24253D
    font-size 16px
    line-height 32px
  &--integration-placements-link
    text-decoration  none !important
  &--integration-placements-img
    margin-left 10px
  &__features
    display flex
    align-items center
    justify-content center
    flex-direction column
    transform translate(0, 50px)
    transition all 1s
    opacity 0
  &--features-content
    max-width: 581px
    margin-top 36px
    color: #24253D
    font-size: 28px
    font-weight: 300
    line-height: 48px
    text-align: center
  &--request
    min-width 148px !important
    transform translate(0, 30vh)
    opacity 0
    transition all 1.5s
  .integration-page-footer__form
     margin-bottom 160px
  .integration-page-footer--input,
  .integration-page-footer--input-home
    width 400px
  .integration-page-footer--congratulations
    color: #24253d;
    font-weight: 400;

  @media screen and (min-width 1024px)
    .a-on-enter
      .home-page-section__integration-placements
        transform translate(0, -140px)
        opacity 1
      .home-page-section__features
        transform translate(0, 0)
        opacity 1
    .a-on-enter-animate
      .home-page-section--header,
      .home-page-section--header-content,
      .home-page-section--request
        transform translate(0, 0)
        opacity 1

  @media screen and (max-width 1024px)
    .home-block
      h1
        font-size 36px
      h3
        font-size 16px
        line-height 24px
    &--header
      transform translate(0, 0)
      opacity 1
      transition all 0
    &--header-content
      transform translate(0, 0)
      opacity 1
      transition all 0
    &--request
      transform translate(0, 0)
      opacity 1
      transition all 0
    &__integration-placements
      transform translate(0, -140px)
      opacity 1
      transition all 0s
    &__integration
      max-height 700px
    &__placements-integration-section
      max-width 600px
      margin-bottom 24px
    &__features
      transform translate(0, 0)
      opacity 1
      transition all 0s
    .integration-page-footer__form
      margin-bottom 100px

  @media screen and (max-width 768px)
    &__placements-integration-section
      padding 37px 26px
    &--header-content
      width: 100%
      font-size 20px
    &__integration
      max-height 650px
    .integration-page-footer--input
      width: 300px;
    .integration-page-footer--input-home
      width 200px

    @media screen and (max-width 460px)
      &__integration
        max-height 700px
      .integration-page-footer__form
        display flex
        justify-content center
        width 100%
      .integration-page-footer--input,
      .integration-page-footer--input-home
        width: 50%
      .integration-page-footer--button
        width 150px !important
        min-width 150px !important
        button
          width 150px !important
          min-width 150px !important
    @media screen and (max-width 374px)
      &__integration
        max-height 750px
