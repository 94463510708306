$bottom-section-bg-color = #1D3892
$bottom-section-text-color = #E1ECF8

.footer
  transition background-color 0.3s ease-in-out
  &.footer-top
    padding 20px 0
  &.footer-bottom
    padding 15px 0
    color $bottom-section-text-color
  &.footer-bottom,
  .footer-link
    font-size 13px
    font-weight 300
  .footer-link
    text-align center
  .social-links
    margin-top -5px
    a
      display inline-block
      margin 3px 10px 0
      vertical-align middle
@media screen and (max-width 727px)
  .footer
    .footer-link
      margin-top 5px
    .social-links
      margin-top 15px