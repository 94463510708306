.dashboard
  width 100%
.job-ads-title
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  span
    font-size: 14px;
    margin-left: 10px;
    line-height: 20px;
    @media screen and (max-width 450px)
      margin-left: 0px;