.card-settings
  max-width 780px
  margin 25px auto

  &__wrapper
    padding 20px 30px 20px 30px
    margin-bottom 25px

    h3 
      margin-bottom 20px

  &__card-number
    display flex
    align-items center
    margin-bottom 15px

  &__card-brand
    height 25px
    width 35px
    background-position center
    background-repeat no-repeat
    background-size cover
    margin-right 10px

