.refari-socialize-modal
  padding: 24px
  color: rgba(60, 72, 86, 0.6) !important
  font-size: 16px
  max-width: 768px !important
  background-color: #fff !important
  .MuiButton-label
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-weight: 500;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
  .refari-field-file-wrap
    .refari-file-input
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      opacity: 0;
  .MuiPaper-elevation1
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  .refari-card-image-holder
    min-height: 100px
  .refari-modal-header
    padding 15px 0
    font-size 24px
    padding-top: 30px !important
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    font-weight: 700 !important;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 36px;
  .refari-card-media__loader-wrapper
    display flex
    justify-content center
    align-items center
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    background-color rgba(255,255,255,.5)
  .refari-card-header
    padding 15px
  .refari-card-title-field
    font-weight 600
  .refari-card-description
    margin-bottom 15px
    line-height 22px
  .refari-card-description-field
    div
      font-size 14px!important
  .refari-field-file-wrap
    display inline-block
    margin-top: 15px;
    margin-bottom: 5px;
  .refari-card-subtitle
    max-width 380px
    display flex
    justify-content space-between
    align-items center
    margin-bottom 15px
    font-size 14px
  .refari-card-tags
    max-width 500px
    display flex
    justify-content space-between
    align-items center
    margin-bottom 15px
    font-size 14px
    padding-top 15px
    @media screen and (max-width 500px)
      flex-wrap wrap
  .refari-social-btn-wrapper
    display none !important
  .refari-direct-link-wrap
    padding 30px 15px
    .refari-copied-wrap
      max-width 300px
      margin 0 auto 30px

  .refari-color-slider
    padding 0 0 15px

.refari-direct-link-wrap
  &__copied-section
    display flex
    flex-direction column
    max-width 500px
    margin: 0 auto
    width 100%
  &--copied-header
    margin-top 24px !important
    margin-bottom 14px !important
    padding-bottom 0 !important

.refari-canvas-wrapper
  position: relative

.refari-socialize__first-step
  padding 0 2px
  .refari-socialize__radio-group
    margin: 10px 0;
    .refari-socialize__radio-buttons
      width: auto !important;
      display: inline-block !important;
      min-width: 180px
    .MuiFormControl-fullWidth
      margin: 0 !important
  .refari-first-step__hint
    font-size 14px
    margin-bottom 0
    line-height: 22px;
  .refari-description-counter
    font-size 12px
    line-height 18px
  .refari-additional-options-row
    font-size 14px
    display flex
    flex-wrap wrap
    margin-left 0 !important
    .MuiFormControl-fullWidth
      margin: 0 !important;
  .refari-background-options
    max-width 500px
    display flex
    margin 0 -2px 15px
    overflow-y auto
    overflow-x visible
    padding-bottom: 5px
    .refari-option-wrapper
      border 1px solid transparent
      position relative
      label.hasVideo
        &::before
          background-image: url("../../assets/images/playicon.svg")
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: center;
          background-color: #80808050;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
    label
      padding 2px
      width: 154px
      max-height: 79px
      overflow: hidden
      margin 0
      cursor pointer
      user-select none
      transition all 0.2s ease
      &:hover
        transform scale(0.9)
        img
          box-shadow 0px 1px 7px 1px rgba(0,0,0,0.3)
    img
      display inline-block
      width 100%
      user-select none
      transition all 0.2s ease
    &::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
      border-radius: 5px
      background-color: #F5F5F5
    &::-webkit-scrollbar
      width: 12px
      height: 12px
      background-color: #F5F5F5
    &::-webkit-scrollbar-thumb
      border-radius: 6px
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
      background-color: #8E8E8E
  .refari-background-options-testimonial
    max-height: 100px;
    display: flex;
    margin: 8px 0;
    position: relative;
    .slick-slider
      width: 100%;
      .slick-list
        margin-bottom: 15px;
    .refari-background-options-testimonial-shadows-left-shadow
      position: absolute;
      display: block;
      width: -moz-fit-content;
      width: fit-content;
      bottom: 0;
      top: 0;
      right: 0;
      z-index: 9;
      img 
        max-width: 50px;
        height: 100%;
    .refari-background-options-testimonial-shadows-right-shadow
      position: absolute;
      display: block;
      width: -moz-fit-content;
      width: fit-content;
      bottom: 0;
      top: 0;
      left: 0;
      z-index: 9;
      img 
        max-width: 50px;
        height: 100%;
    .refari-option-testimonial-wrapper
      border 1px solid transparent
      padding: 0 2px;
      img
        max-height: 100px;
    label
      padding 2px
      margin 0
      cursor pointer
      user-select none
      transition all 0.2s ease
      img
        box-shadow 0px 1px 2px 1px rgba(0,0,0,0.3)
      &.selected-background-image
        img 
          box-shadow: none
      &:hover
        transform scale(0.9)
        img
          box-shadow 0px 1px 7px 1px rgba(0,0,0,0.3)
    img
      display inline-block
      width 100%
      user-select none
      transition all 0.2s ease
  .refari-canvas-wrapper-testimonial
    .konvajs-content
      box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
  .refari-font-selector
    padding-top 10px
    margin-bottom 25px
    .MuiAutocomplete-endAdornment
      top: calc(50% - 22px);
    &__label
      font-size  14px
      span
        color rgba(0, 0, 0, 0.54)
        line-height 14px
    &__provider
      padding-top 15px
      font-size: 12px
      color rgba(0, 0, 0, 0.54)


.refari-socialize__second-step
  padding 10px 5px 15px
  .refari-social-btn-wrapper
    margin-bottom 40px
  .refari-socialize_networks
    margin-bottom 25px
    display flex
    flex-wrap wrap
    .refari-socialize_network
      display: flex
      flex 1
      width 33.333333% !important
      min-width 165px !important
      .refari-socialize_network__label
        display flex
        flex-wrap wrap
        span
          flex 1 0 100%
          font-size 12px
          display flex
          margin 5px 0 0px
          svg
            height 15px !important
            width 15px !important
      svg
        margin 0 5px
  .refari-socialize_companies
    margin-bottom 20px
  .refari-socialize__need-invite
    margin-bottom 15px
  .refari-direct-link-wrap
    padding 0 15px 15px
    h4
      padding-bottom 15px

.refari-socialise-next-button 
  &.Mui-disabled
    opacity: .5

@media screen and (max-width 792px)
  .refari-socialize__second-step
    .refari-social-btn-wrapper
      display block
    .refari-col
      padding 0
      margin-bottom 15px
    .refari-social-btn-section
      flex-wrap wrap
