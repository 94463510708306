.application-policies-widget
    margin-top 10px
    margin-bottom 10px
    &__link
      position relative
      margin-bottom 10px
      &:hover
        .application-policies-widget--close
            opacity 1
    &--close
      position absolute !important
      top 10px
      right 10px
      opacity 0
      button
        width 28px !important
        height 28px !important
      svg
        width 20px !important
        height 28px !important
