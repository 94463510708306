.activities-page
  .table
    tbody td:first-child a
      position relative
      padding-left 50px

      &:before
        content ''
        display block
        position absolute
        left 24px
        top 50%
        margin-top -4px
        border-radius 50%
        width 8px
        height 8px
        vertical-align middle

    .application-created td:first-child a:before
      background-color #4ab6fe
    .application-created-linkedin td:first-child a:before
      background-color #2024ec
    .job-shared td:first-child a:before
      background-color #FE7C7A
    .job-saved td:first-child a:before
      background-color $teal
    .job-rated td:first-child a:before
      background-color #966a03
    .publication-created td:first-child a:before
      background-color #b8e985
    .subscription-created td:first-child a:before
      background-color #c4daf1
    .talent-alert-subscription-created td:first-child a:before
      background-color: #800080
    .job-removed td:first-child a:before
      background-color #009688
    .subscription-removed td:first-child a:before
      background-color #ABB8C3
    .talent-alert-subscription-removed td:first-child a:before
      background-color: #8B0000
    .candidate-referred-crm td:first-child a:before
      background-color #795548
    .candidate-referred td:first-child a:before
      background-color #ff9800
    .suggestion_accepted td:first-child a:before
      background-color #116804
    .talent_alert_suggestion_accepted td:first-child a:before
      background-color #006400
    .suggestion_created td:first-child a:before
      background-color #68b65a
    .talent_alert_suggestion_created td:first-child a:before
      background-color #B8860B
    .testimonial_published td:first-child a:before
      background-color #0074BC
    .testimonial_created td:first-child a:before
       background-color #b9058c
    .candidate_created td:first-child a:before
       background-color #5239c2
    .feedback-created td:first-child a:before
       background-color #3fd342
    .testimonial_approved td:first-child a:before
      background-color #722e3a
    .testimonial_revised td:first-child a:before
      background-color #c77481
    .testimonial_updated td:first-child a:before
      background-color #fccad2

  // Activities filter select menu items color encoding on the basis of data-value
  .checked-multiselect
    width: 320px;
    ul
      li
        &:before
          content ''
          display block
          position absolute
          right 44px
          top 50%
          margin-top -4px
          border-radius 50%
          width 8px
          height 8px
          vertical-align middle
          background-color #4ab6fe
        &[data-value="application_created"]:before
          background-color #4ab6fe
        &[data-value="application_created_linkedin"]:before
          background-color #2024ec
        &[data-value="job_shared"]:before
          background-color #FE7C7A
        &[data-value="job_saved"]:before
          background-color $teal
        &[data-value="job_rated"]:before
          background-color #966a03
        &[data-value="publication_created"]:before
          background-color #b8e985
        &[data-value="subscription_created"]:before
          background-color #c4daf1
        &[data-value="talent_alert_subscription_created"]:before
          background-color: #800080
        &[data-value="job_featured_deleted"]:before
          background-color #009688
        &[data-value="subscription_deleted"]:before
          background-color #ABB8C3
        &[data-value="talent_alert_subscription_deleted"]:before
          background-color: #8B0000
        &[data-value="candidate_referred_crm"]:before
          background-color #795548
        &[data-value="candidate_referred"]:before
          background-color #ff9800
        &[data-value="suggestion_accepted"]:before
          background-color #116804
        &[data-value="talent_alert_suggestion_accepted"]:before
          background-color #006400
        &[data-value="suggestion_created"]:before
          background-color #68b65a
        &[data-value="talent_alert_suggestion_created"]:before
          background-color #B8860B
        &[data-value="testimonial_published"]:before
          background-color #0074BC
        &[data-value="testimonial_created"]:before
          background-color #b9058c
        &[data-value="candidate_created"]:before
          background-color #5239c2
        &[data-value="feedback_created"]:before
          background-color #3fd342
        &[data-value="testimonial_approved"]:before
          background-color #722e3a
        &[data-value="testimonial_revised"]:before
          background-color #c77481
        &[data-value="testimonial_updated"]:before
          background-color #fccad2

.activity-subject
  .application-created
    background-color #4ab6fe
  .application-created-linkedin
    background-color #2024ec
  .job-shared
    background-color #FE7C7A
  .job-saved
    background-color $teal
  .job-rated
    background-color #966a03
  .publication-created
    background-color #b8e985
  .subscription-created
    background-color #c4daf1
  .talent-alert-subscription-created
    background-color: #800080
  .job-removed
    background-color #009688
  .subscription-removed
    background-color #ABB8C3
  .talent-alert-subscription-removed
    background-color: #8B0000
  .candidate-referred-crm
    background-color #795548
  .candidate-referred
    background-color #ff9800
  .suggestion_accepted
    background-color #116804
  .suggestion_created
    background-color #68b65a
  .talent_alert_suggestion_accepted
    background-color #006400
  .talent_alert_suggestion_created
    background-color #B8860B
  .testimonial_published
    background-color #0074BC
  .testimonial_created
    background-color #b9058c
  .candidate_created
    background-color #5239c2
  .feedback-created
    background-color #3fd342
  .testimonial_approved
    background-color #722e3a
  .testimonial_revised
    background-color #c77481
  .testimonial_updated
    background-color #fccad2
