.auth-modal
  h3
    text-align center
    font-weight 700
    padding-top 35px
  .right-side
    padding 0 15px 0 30px
    h3
      padding-bottom 20px
  .left-side
    padding 0 30px 0 15px
    h3
      padding-bottom 35px
      &.with-form
        padding-bottom 20px
  .links-to-static
    font-size 14px
    font-weight 400
    line-height 21px
  .forgot-password-btn
    margin 10px auto
  .verify-form
    max-width 400px
    margin 0 auto
  .refari-reset-password
    .align-center
      h2
        font-size: 30px;
        line-height: 54px;
        font-weight: 700;
      h5
        padding: 10px 0 15px 0;
        margin: 0;
        font-size: 16px;
      p
        margin-top: 0;
        margin-bottom: 14px;
        font-size: 16px;
        font-weight: 700;
        color: #3E4044;

@media screen and (max-width 792px)
  .auth-modal
    .right-side, .left-side
      padding 0 15px
    .vertical-divider
      display none
      