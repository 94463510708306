section-bg-color = #f9fbfe
text-color = #24253d
.sales
  width 100%
  color text-color
  .sales__section
    padding-top 50px
    padding-bottom 50px
    @media screen and (min-width 768px)
      padding-top 60px
      padding-bottom 60px
  .h1
    font-size 36px
    font-weight 300
    line-height 54px
  .h2
    font-size 28px
    font-weight 300
    line-height 48px
    margin-bottom 30px
  .section__subtitle
    color #24253d
    font-size 24px
    font-weight 400
    line-height 40px
  .section__description
    font-size 16px
    font-weight 400
    line-height 32px
  &__gamification
    padding-top 0
    padding-bottom 120px
    background-color #f9fbfe
    @media screen and (max-width 1024px)
      padding-top 0
      padding-bottom 60px
  &--gamification-header
    margin-top 100px
    @media screen and (max-width 1024px)
       margin-top 30px
  &__gamification-image
     max-width 100%
     margin-left: auto
  &__gamification-section
    @media screen and (max-width 1024px)
      display: flex
      flex-direction column-reverse


  // 1
  .section-hero
    background section-bg-color
    min-height 90vh
    position relative
    overflow hidden
    .h1
      margin-bottom 30px
      @media screen and (min-width 768px)
        margin-bottom 40px
        padding-top 15%
    .section__subtitle
      margin-bottom 30px
      @media screen and (min-width 768px)
        margin-bottom 70px

    .section-hero__banner
      @media screen and (max-width 1024px)
        display none
      img
        display block
        width 100%

    // animation
    .a-left-enter,
    .a-right-enter
      will-change transform
      transition transform 1s cubic-bezier(0.165, 0.84, 0.44, 1)
    .a-left-enter
      transform translateX(-120%)
    .a-right-enter
      transform translateX(120%)
    &.a-on-enter
      .a-right-enter
      .a-left-enter
        transform translateX(0%)
  // 2
  .section-benefits
    background-image url('../../assets/images/hero_bg.png')
    background-repeat no-repeat
    background-size 100%
    min-height 70vh
    overflow hidden
    .section__description
      font-size 15px
    .slick-list
      overflow initial
    .slick-track
      padding-bottom 5px
    .section-benefits__card
      padding 48px 35px
      box-shadow 0 1px 15px 1px rgba(0, 0, 0, 0.05)
      border-radius 6px
      background-color #ffffff
      max-width 370px
      min-height 355px
      margin 0 10px
      @media screen and (min-width 425px)
        margin 0 auto
      h3
        font-size 24px
        font-weight 500
        line-height 40px
        margin-bottom 10px
    .section-benefits__icon
      background-image url('../../assets/sprites/icons.svg')
      background-repeat no-repeat
      margin-bottom 30px
    .icon-flexibility
      width 30px
      height 30px
      background-position 0 -48px
    .icon-reliability
      width 28px
      height 34px
      background-position 0 -78px
    .icon-transparency
      width 30px
      height 30px
      background-position 0 -112px
    // animation
    .section-benefits__card
      opacity 0
      transform translate(0, 50%)
      transition transform 1s cubic-bezier(0.11,0.29,0,0.97), opacity 0.4s cubic-bezier(0.3,0.02,0.44,0.26)
      will-change transform, opacity
    &.a-on-enter
      .section-benefits__card
        opacity 1
        transform translate(0, 0%)

  // 3
  .section-conversion
    position relative
    @media screen and (min-width 768px)
      padding: 70px 0
    .section-conversion__bg
      display none
      @media screen and (min-width 768px)
        display block
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        background-image url('../../assets/images/sales-page_users-illustration.svg')
        background-repeat no-repeat
        background-position: center
        background-size 100% auto
        transform translate(0, 60%)
        transition transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1)
    h2
      margin-bottom 30px
    .section-conversion__list
      list-style-position inside
      padding-left 0px
      li
        font-size 16px
        font-weight 400
        line-height 32px
    // animation
    .a-bottom-enter
      transform translate(0, 30%)
      transition transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s
      will-change transform
    &.a-on-enter
      .section-conversion__bg
        @media screen and (min-width 768px)
          transform translate(0, 0%)
      .a-bottom-enter
        transform translate(0, 0%)

  // 4
  .section-features
    position relative
    &:before
      content ''
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      background-color section-bg-color
      z-index -1
      border-radius 0
      @media screen and (min-width 768px)
        border-radius 58% 58% 0 0
    h2
      margin-bottom 40px
    .section-features__icon
      background-image url('../../assets/sprites/icons.svg')
      background-repeat no-repeat
      background-position 0 0
      width 48px
      height 48px
      margin 0 auto 65px
    // animation
    .a-bottom-enter,
    .a-scale-in
      opacity 0
    .a-scale-in
      transform scale(0)
      transition transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      will-change transform, opacity
    .a-bottom-enter
      transform translate(0, 100px)
      transition transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      will-change transform, opacity
    &.a-on-enter
      .a-bottom-enter
      .a-scale-in
        opacity 1
      .a-scale-in
        transform scale(1)
      .a-bottom-enter
        transform translate(0, 0%)
  // 5
  .section-widgets
    background-color section-bg-color
    .container:first-child
      margin-bottom 60px
    .section-widgets__previews
      position relative
      max-width 600px
      width 100%
      margin 0 auto 130px
      img
        display block
      .widget-desktop-preview
        max-width 90%
      .widget-tablet-preview,
      .widget-mobile-preview
        position absolute
      .widget-tablet-preview
        max-width 43%
        right 0
        bottom -30px
      .widget-mobile-preview
        max-width 26%
        right 25%
        bottom -30%
    .widgets-slider
      padding 40px 0 0
      .widgets-slider__preview
        display block
        width 100%
        -webkit-user-select none
        -moz-user-select none
        user-select none
      .widgets-slider__item
        @media screen and (min-width 1024px)
          max-width 400px
      .slick-list
        @media screen and (min-width 1024px)
          margin-bottom 50px
      .slick-track
        padding: 10px 0px
        @media screen and (min-width 1024px)
          padding: 30px 0px
      .slick-slide
        img
          margin 0 auto
          max-width 80%
          @media screen and (min-width 1024px)
            max-width initial
            position relative
            transform scale(1)
            transition transform 0.1s ease
      .slick-slide.slick-active
        img
          @media screen and (min-width 1024px)
            transition transform 0.6s ease 0.5s
            transform scale(1.3)
            z-index 2
    // animation
    .a-bottom-enter
      opacity 0
      transform translate(0, 50px)
      transition transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      will-change transform, opacity
    &.a-on-enter
      .a-bottom-enter
        opacity 1
        transform translate(0, 0%)
  // 6
  .section-socialization
    .section-socialization__previews
      position relative
      max-width 600px
      width 100%
      margin 0 auto
      @media screen and (max-width 1024px)
        margin-bottom 40px
      @media screen and (min-width 1024px)
        max-width 100%
      img
        display block
      .socialize-preview-bg
        max-width 51%
        position relative
        transform translateX(60%)
      .socialize-preview-1,
      .socialize-preview-2
        max-width 55%
        position absolute
      .socialize-preview-1
        top 0
        left 0
      .socialize-preview-2
        right 5%
        bottom 5%
    // animation
    .a-bottom-enter
      opacity 0
      transform translate(0, 50px)
      transition transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      will-change transform, opacity
    &.a-on-enter
      .a-bottom-enter
        opacity 1
        transform translate(0, 0%)
  // 7
  .section-positioning
    background-color section-bg-color
    .section-positioning__previews
      position relative
      max-width 600px
      width 100%
      margin 0 auto
      padding-top 80%
      @media screen and (max-width 1024px)
        margin-bottom 40px
      @media screen and (min-width 1024px)
        max-width 100%
      @media (min-width 768px) and (max-width 1024px)
        padding-top 50%
      img
        display block
      .socialize-preview-bg
        position relative
        transform translateX(60%)
      .profile-preview,
      .meet-the-team-preview
        position absolute
        max-width 80%
      .profile-preview
        top 0
        left 0
      .meet-the-team-preview
        right 0
        bottom 0
    // animation
    .a-bottom-enter
      opacity 0
      transform translate(0, 50px)
      transition transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      will-change transform, opacity
    &.a-on-enter
      .a-bottom-enter
        opacity 1
        transform translate(0, 0%)
  // 8
  .section-alerts
    .section-alerts__previews
      position relative
      max-width 600px
      width 100%
      margin 0 auto
      padding-top 20%
      @media screen and (max-width 1024px)
        margin-bottom 40px
      @media screen and (min-width 1024px)
        max-width 100%
      img
        display block
      .alerts-preview-bg
        max-width 60%
        position relative
        transform translateX(40%)
      .alerts-preview-1,
      .alerts-preview-2
        position absolute
      .alerts-preview-1
        max-width 55%
        top 0
        left 0
      .alerts-preview-2
        max-width 45%
        right 10%
        bottom 5%
    // animation
    .a-bottom-enter
      opacity 0
      transform translate(0, 50px)
      transition transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      will-change transform, opacity
    &.a-on-enter
      .a-bottom-enter
        opacity 1
        transform translate(0, 0%)
  // 9
  .section-request
    color #ffffff
    background-color $blue
    padding 90px 0
    form > div
      margin-bottom 30px
    // animation
    &.a-bottom-enter
      opacity 0
      transition opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      will-change opacity
    &.a-on-enter
      &.a-bottom-enter
        opacity 1
