.question-mapping-container
  max-width: inherit !important;
.question-mapping-section
  &__top
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: #0000001f 0px 1px 6px, #0000001f 0px 1px 4px;
  &__tab_heading
    box-shadow: none !important;
    .Mui-selected
      box-shadow: 0 1px 5px #00000021, 0 1px 2px #00000021
    .MuiTab-root
      margin-right: 5px
  &-add-question
    padding: 0 15px;
  &-questions
    &-td
      font-weight: 500;
      line-height: 2;
      padding: 5px 0;
      font-size: 14px;
      position: relative;
      .anchor-txt  
        padding-left: 15px;
      .settings-icon-box
        position: absolute;
        right: 0;
        top: 5px;
      .delete-icon-box
        position: absolute;
        right: 30px;
        top: 5px;
    &-item
      text-indent: -1em;
      padding-left: 1em;
      padding-right: 30px;
      span
        font-weight: 300;
        font-size: 13px;
        color: #7a7a7a;
  &-jobs
    &-td
      line-height: 2;
      padding: 5px 0;
      font-size: 14px;
      position: relative
      .anchor-txt  
        position: absolute;
        right: 0;
        bottom: 5px;
    &-data
      width: calc(100% - 55px);
    &-item
      hyphens: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  &-candidate-main-td
    min-height: 80px;
  &-candidate-td
    border: 1px solid #ccc;
    padding: 5px 10px 0 15px;
    margin: 5px 0px;
    border-radius: 5px;
    hr
      display: none !important
  &-settings-td
    .MuiFormControl-root.MuiFormControl-fullWidth
      margin-top: 0;
      margin-bottom: 8px;
  &-settings-update-button
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
  &-settings-all-buttons
    display: flex;
    justify-content: space-between;
  .anchor-txt
    font-weight: normal;
    font-size: 12px;
    cursor: pointer
  .clear-data-btn
    font-size: 12px;
    font-weight: normal;
    margin-top: 10px;
    text-align: right;
    span
      cursor: pointer
  &-table-body
    tr
      border-bottom: 2px solid #ccc !important;
    td
      white-space: unset !important;
      vertical-align: top;
      background-color: inherit !important;
      border-right: 2px solid #ccc !important;
      &:last-child
        border-right none !important;
  &-answer-modal-question
    font-weight: 500;
    color: #404144;
    font-size: 16px;
    margin: 10px;
  &-answer-modal-answer
    font-weight: normal;
    font-size: 15px;
    margin: 15px 10px;
    color: rgb(117, 117, 117)
  &-answer-modal-wrapper
    .refari-field-tags-wrap
      margin-top: 10px
.empty-value
  color: #ccc !important

.settings-section
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  .refari-select-wrap
    width: 40%
  .MuiCheckbox-root
    padding-bottom: 0;
    padding-top: 0;
  .MuiFormControlLabel-root
    margin: 0;


.refari-add-questions
  &-label
    font-size: 12px;
    color: #515151;
  &-value
    font-size: 16px;
    margin-top: 5px;
  &-question
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    &-value
      border: 1px solid #ccc;
      padding: 5px 10px;
  &-ul
    border: 1px solid #ccc;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: scroll;
    &-li
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 5px 7px 15px;
      border-bottom: 1px solid #ccc;
