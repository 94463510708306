.details
  padding 20px
  position relative
  width 100%
  &.loading
    overflow hidden
    height 100vh
    opacity .5
  h3
    margin-bottom 15px
    padding-right 30px
    word-break break-word
  dl
    dt
      font-weight 400
      color #9e9e9e
    dd
      word-break break-word
  .details-divider
    margin-bottom 15px !important
  .details-message
    margin-bottom 30px
  .details-success
    height 150px
    display flex
    align-items center
    text-align center
    font-style italic
  .details-section
    margin-bottom 30px
  &__unlink-section
    padding-top 26px
    text-align center