.widgets
  width 100%
  position: relative
  &__container
    padding-top 25px
    padding-bottom 25px
  &-wrapper
    padding 20px 30px 50px 30px
  
  &-wrapper-title-wrap
    display flex
    align-items center
    flex-wrap wrap
    justify-content space-between
    width: 100%

  &__snippet-preview
    margin 0 -30px 20px

  &__action-wrapper
    margin-bottom 20px

  &__image-preview
    display block
    width 100%
    margin-bottom 20px
  &__image-preview-register
    display: block
    width: 300px
    margin: 0 auto 14px

  h3
    margin-bottom 20px

  h4
    margin-bottom 15px

.floating-link
  &.your-rating-widget
    .ui-placeholder
      margin-left 40px
  .widget__live-preview
    text-align center
    padding 1em 1em 0
    border 1px solid rgba(0,0,0,0.12)
    margin-bottom 1em
    position relative
    .ref-floating-link
      position absolute
    .ref-average-rating
      position absolute
      bottom: 90%;
      box-shadow: 1px 1px 10px rgba(0,0,0,.5)
      -webkit-box-shadow: 1px 1px 10px rgba(0,0,0,.5)
      -moz-box-shadow: 1px 1px 10px rgba(0,0,0,.5)
      > span
        text-decoration: none
        .ref-average-rating-box
          padding: 0px 15px
          display: flex;
          justify-content: center;
          align-items: center;
          span
            &.ref-widget-text
              transform: rotate(180deg);
              font-size: 14px;
            &.ref-widget-stars
              transform: rotate(180deg);
              svg
                margin: 4px 0px !important;
