$default-link-color = #7f8197
$hover-link-color = darken($default-link-color, 20%)
$active-link-color = $blue
.integration-page-navigation
  display flex
  align-items center
  .navigation__btn
    &:hover
      @media screen and (max-width 768px)
        background-color $active-link-color !important
      span
        color #fff !important
    span
      @media screen and (max-width 560px)
        font-size 10px !important

.header--sales
  .sales-page-navigation
    right 0
    transform translateX(0%)
    transition right 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)
    @media screen and (max-width 374px)
      padding-top 15px
  &.is-minified
    .sales-page-navigation
      @media screen and (min-width 560px)
        position relative
        right 50%
        transform translateX(50%)
    .navigation__btn
      @media screen and (max-width 560px)
        min-width 125px !important
      @media screen and (min-width 561px)
        min-width 150px !important

.integration-page
  width 100%
  margin-top: 80px;
  position: relative;
  overflow: hidden;
  color: #24253d;

  .integration-page-header
    height 600px
    position relative
    background-image url("../../assets/images/integration_bg.png")
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position center
    .section-conversion__bg
      display none
      @media screen and (min-width 768px)
        display block
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        background-image url('../../assets/images/sales-page_users-illustration.svg')
        background-repeat no-repeat
        background-position: center
        background-size 100% auto
        transform translate(0, 60%)
        transition transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1)
        opacity 0
    &.a-on-enter
      .section-conversion__bg
        @media screen and (min-width 768px)
          transform translate(0, 0%)
          opacity 1
      .a-bottom-enter
        transform translate(0, 0%)
        opacity 1
    &__flex
      width: 100%;
      display flex
      align-items center
      justify-content center
      flex-direction column
    &--header
      max-width: 686px
      font-size: 36px
      font-weight: 300
      line-height: 54px
    &--content
      margin-top 27px
      margin-bottom 60px
      max-width: 600px
      text-align center
      font-size: 24px
      font-weight: 400
      line-height: 40px

  .integration-page-ats
    margin-top 120px
    margin-bottom 120px
    &__section
      display flex
      flex-direction column
      align-items center
      width 100%
      transition all 1s
      transform translate(0, 50%)
      opacity 0
    &--header
      margin-bottom 120px
      max-width: 600px
      font-size: 28px
      font-weight: 300
      line-height: 48px
    &__content
      display flex
    &__content-left
      max-width: 430px
      text-align: left
      transition all 1s
      transform translate(-50%, 0)
      opacity 0
    &--content-section-header
      font-size: 28px
      font-weight: 300
      line-height: 48px
    &--content-section-active
      margin-left 5px
      text-decoration underline
      cursor pointer
      color: $primary
    &--content-section
      margin-top 21px
      font-size: 16px
      font-weight: 400
      line-height: 32px
    &__paper
      display flex
      align-items center
      justify-content center
      flex-direction column
      width: 200px
      height: 200px
      border-radius 16px !important
    &--img
      width 70%
    &__content-right
      display flex
      align-items center
      justify-content center
      margin-left 30px
      transition all 1s
      transform translate(50%, 0)
      opacity 0
    &--plus
      margin-left 18px
      margin-right 18px
  .flex-column-center
    display flex
    align-items center
    justify-content center
    flex-direction column
  .integration-page-solution
    &--header
      font-size: 28px
      font-weight: 300
      line-height: 48px
      text-align center
    &--header-content
      max-width 430px
      margin-top 18px
      text-align center
      font-size 16px
      font-weight 400
      line-height 32px
    &--info
      display flex
      flex-wrap wrap
      justify-content center
      margin 50px auto
      max-width 806px
    &__paper
      display flex
      align-items center
      justify-content center
      width 170px
      height 170px
      margin 15px
      border-radius 12px !important
      cursor pointer
      &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important
      }
    &--footer
      text-align center
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
  .integration-page-contact
     margin-top 135px
     background-image url('../../assets/images/footer-integration-bg.png')
     display flex
     align-items center
     flex-direction column
     height 532px
     background-position center
     background-repeat no-repeat
     background-size: 100% 100%;
     &__icon
       background-image url('../../assets/sprites/integration-settings.svg')
       background-repeat no-repeat
       background-position 0 0
       transition all 1s
       width 48px
       height 48px
       margin 0 auto 36px
       transform scale(0)
     &--header
       text-align center
       font-size: 28px
       font-weight: 300
       line-height: 48px
     &--content
       max-width: 575px
       margin-top 17px
       margin-bottom 40px
       text-align center
       font-size: 16px
       font-weight: 400
       line-height: 32px
     &__content
       display flex
       align-items center
       flex-direction column
       width 100%
       margin-top 120px
  .integration-page-footer
    &__container
      display flex
      justify-content center
      width 100%
      padding-top 96px
      padding-bottom 96px
      background-color: $blue
      transition all 1s
      opacity 0
    &__section
      width 100%
      display flex
      flex-direction column
      align-items center
    &--header
      margin-bottom 56px
      color: #ffffff
      text-align center
      font-size: 28px
      font-weight: 300
      line-height: 48px
    &--input
      width 430px !important
  &--animation
    transition transform 1s, opacity  1s
    transform translate(0, 20vh)
    opacity 0
  .a-on-enter
    .integration-page-ats__section,
    .integration-page-ats__content-left,
    .integration-page-ats__content-right,
    .integration-page--animation
    .integration-page-footer__container
      transform translate(0, 0)
      opacity 1
    .integration-page-contact__icon
      transform: scale(1)
  @media screen and (max-width 991px)
      margin-top 60px
      .integration-page-ats
        margin-top: 80px
        margin-bottom: 80px
        &--header
          margin-bottom 50px
          text-align center
        &__content
          flex-direction: column
        &--content-section-header
          text-align center
        &--content-section
          max-width 530px
          text-align center
        &__content-right
          margin-left 0
          margin-top 7px
      .integration-page-solution
        &--info
          margin-top 10px
          margin-bottom  30px
      .integration-page-contact
        margin-top 80px
        height: 480px;
        &__content
          margin-top 100px
  @media screen and (max-width 640px)
    .integration-page-footer
      &__form
        width 90%
        display flex
      &--input
        width 60% !important

  @media screen and (max-width 480px)
        .integration-page-ats
          &__content-right
             display flex
             flex-direction column
             align-items center
          &--plus
             display none
          &__paper:first-child
             margin-bottom 20px
        .integration-page-footer
          &__form
            width 100%
            justify-content: center
          &--input
            width 56% !important
          &--button
             min-width 40% !important
             width 40% !important
             button
               min-width 100% !important
               width 100% !important
             span
               padding-left 0 !important
               padding-right 0 !important
