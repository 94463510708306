a.link, button.link
  font-size 14px
  display flex
  cursor pointer
  padding 0
  &.inline
    display inline-flex
  &:hover, &:active
    text-decoration underline
  &:hover
    color #1565c0
  &.btn-link
    background none
    border none
    outline none
  &.small
    font-size 12px