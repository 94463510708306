.welcome
  width 100%
  min-height 570px
  transition background-color 0.3s ease-in-out
  .welcome-successful-img
    margin-right 15px
  .welcome-block
    display flex
    flex-direction column
    h1
      width 100%
      margin-bottom 35px
      opacity 0
      transform translate(0, 30px)
      transition all 0.2s ease-in-out 0.2s
      &.show
        transform translate(0, 0px)
        opacity 1
    h3
      font-weight 300
      margin-bottom 35px
    a
      display inline-block
    h1,
    h3,
    a
      position relative
  
  .welcome-background
    transition background-color 0.3s ease-in-out
    > div 
      transition background-color 0.3s ease-in-out

@media screen and (max-width 860px)
  .welcome__buttons
    flex-direction column
    > div
      margin-bottom 20px
      
@media screen and (max-width 1023px)
  .welcome-block
    h1
      font-size 36px
    h3
      font-size 16px
      line-height 24px