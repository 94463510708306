/*
 Text align utilities following TailwindCSS convention
 see https://tailwindcss.com/docs/text-align
*/

.text-center
  text-align: center

.text-right
    text-align: right
