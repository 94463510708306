.testimonials-feedback
  max-width 557px
  width 100%
  margin-top 50px
  margin-bottom 100px
  &--field-section
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    &-full
      width: 100%
    &-left
      width: 48%
      @media screen and (max-width 400px)
        width: 100%
    &-right
      width: 48%
      @media screen and (max-width 400px)
        width: 100%
    &-right-select
      width: 48%
      margin-bottom: 2px;
      text-align: left;
      .MuiInputLabel-shrink
        white-space: nowrap;
      @media screen and (max-width 400px)
        width: 100%
        margin-top: 15px;
      @media screen and (max-width 500px)
        .MuiInputLabel-shrink
          white-space: initial;
  &__header
    display flex
    align-items center
    justify-content center
    padding-top 36px
    padding-bottom 36px
  &--header-content
    margin-bottom: 0
    text-align center
    color: #3886D1
    font-size 24px
  &--content-text
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #9E9E9E;
  &__content
    display: flex
    align-items: center
    flex-direction: column
    min-height 400px
    padding 36px 45px 60px
    background: #EEF7FF
    text-align: center
  &__review
    display: flex
    align-items: center
    justify-content center
    padding 36px 35px
    text-align: center
  &--comments-description
    margin-top: 24px
    margin-bottom: 0
    color: #9E9E9E
    line-height: 24px
    text-align: center
    font-style: normal
    font-weight: normal
    font-size: 14px
  &--review-input
    width: 70% !important
    margin-right 14px
    text-align: left
  &--social
    min-width 126px !important
    margin-left 14px
    margin-right 14px
    word-break: break-all
  &--content
    font-weight: 500
    font-size: 16px
    line-height: 24px
    color: #3886D1
    span
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  &--content-lamp
    margin-bottom 17px
  &--content-small
    width 290px
  &--content-text-add
    max-width 347px
  &--stars
    width: 16px
    height: 16px
    margin 0 5px
  &--user-name
    margin-top 14px
    margin-bottom 0
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 24px
    color: #757575
  &--content-header
    padding-top 20px
    padding-bottom 20px
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 24px
    text-align: center
    color: #3886D1
  &__thank-modal
    max-width 557px
    width: 100%
    height: 324px
    padding-top 60px
    display flex
    flex-direction column
    align-items center
  &_approve__thank-modal
    max-width 757px
    width: 100%
    padding: 30px;
    display flex
    flex-direction column
    align-items center
    .feedback-page-comment
      padding: 0 !important;
      width: 100%;
  &__button
    padding-top 30px !important
  &__share-modal
    padding-top 40px !important
    .testimonials-feedback--content-text-add
      max-width 370px
      font-size: 15px
    .testimonials-feedback--user-name
      font-size: 1rem;
    .testimonials-feedback__stars
      margin-top: 6px;
    .testimonials-feedback--stars
      width: 20px;
      height: 20px;
    .testimonials-feedback__share-review
      padding: 0px 30px;
      text-align: center;
      margin-bottom: 20px;
      .title
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        text-align: left;
        color: #9e9e9e;
      .testimonials-feedback--review-input-block
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 8px;
        padding: 0 20px;
        height: 140px !important;
        position: relative !important;
        .testimonials-feedback--review-input
          width: 100% !important;
          margin-right: 0;
          text-align: left;
          hr
            display: none !important
        .testimonials-feedback--review-button
          position: absolute !important;
          right: 10px;
          bottom: 10px;
          svg
            height: 20px !important;
            width: 20px !important;
    .testimonials-feedback__add-modal-submit
      padding: 0px 30px;
      text-align: center;
      .social-title
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        span
          margin: 0 15px;
          font-weight: normal;
          font-size: 15px;
          color: #9e9e9e;
        &:before, &:after
          background: #cccccc;
          height: 2px;
          flex: 1;
          content: '';
      .testimonials-feedback--social
        margin-left: 0;
        margin-right: 0;
        word-break: break-all;
        width: 100%;
        margin-top: 0;
        margin-bottom: 12px;
  &__add-modal
    max-width 557px
    width: 100%
    padding-top 60px
    display flex
    flex-direction column
    align-items center
    .testimonials-feedback--user-name
      margin-bottom 5px
    .testimonials-feedback__content
      min-height unset
      width 100%
      padding: 36px 45px

  &__add-modal-submit
    flex-wrap wrap
    padding 14px 36px 0
  &__stars
    display flex
    justify-content center
    margin-top 14px
  @media screen and (max-width 768px)
    width 90%
    margin-left 5%
    margin-right 5%
    &__content
      padding 30px 24px 40px
    &__review
      padding 24px 16px
    &__add-modal-submit
      padding 14px 15px 0

.align-left
   text-align left
.flex-around
  display flex
  justify-content space-around

.testimonials-feedback__add-modal-submit
  form
    .MuiOutlinedInput-multiline
      padding: 0 8px;
