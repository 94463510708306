$default-link-color = #7f8197
$hover-link-color = darken($default-link-color, 20%)
$active-link-color = $blue
.about-page-section-navigation
  display flex
  align-items center
  .navigation__btn
    &:hover
      @media screen and (max-width 768px)
        background-color $active-link-color !important
      span
        color #fff !important
    span
      @media screen and (max-width 560px)
        font-size 10px !important

.header--sales
  .sales-page-navigation
    right 0
    transform translateX(0%)
    transition right 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)
    @media screen and (max-width 374px)
      padding-top 15px
  &.is-minified
    .sales-page-navigation
      @media screen and (min-width 560px)
        position relative
        right 50%
        transform translateX(50%)
    .navigation__btn
      @media screen and (max-width 560px)
        min-width 125px !important
      @media screen and (min-width 561px)
        min-width 150px !important

.about-page-section
  width 100%
  margin-top: 80px;
  position: relative;
  overflow: hidden;
  color: #24253d;

  .about-page-section-header
    height 600px
    position relative
    background-image url("../../assets/images/integration_bg.png")
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position center
    .section-conversion__bg
      display none
      @media screen and (min-width 768px)
        display block
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        background-image url('../../assets/images/sales-page_users-illustration.svg')
        background-repeat no-repeat
        background-position: center
        background-size 100% auto
        transform translate(0, 60%)
        transition transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1)
        opacity 0
    &.a-on-enter
      .section-conversion__bg
        @media screen and (min-width 768px)
          transform translate(0, 0%)
          opacity 1
      .a-bottom-enter
        transform translate(0, 0%)
        opacity 1
    &__flex
      width: 100%;
      display flex
      align-items center
      justify-content center
      flex-direction column
    &--header
      max-width: 686px
      font-size: 36px
      font-weight: 300
      line-height: 54px
    &--content
      margin-top 27px
      margin-bottom 60px
      max-width: 600px
      text-align center
      font-size: 24px
      font-weight: 400
      line-height: 40px

  .about-page-section-recruitment
    margin-top 120px
    margin-bottom 120px
    &__section
      display flex
      flex-direction column
      align-items center
      width 100%
      transition all 1s
      transform translate(0, 50%)
      opacity 0
    &--header
      margin-bottom 120px
      max-width: 600px
      font-size: 28px
      font-weight: 300
      line-height: 48px
      text-align center
    &__content
      display flex
      justify-content space-between
      width 65%
    &--content
      margin-bottom 0
    &__content-left-first
      margin-bottom 40px
    &__content-left
      max-width: 430px
      text-align: left
      transition all 1s
      transform translate(-50%, 0)
      opacity 0
    &__content-right
      display flex
      align-items center
      justify-content center
      flex-direction: column
      margin-left 30px
      transition all 1s
      transform translate(50%, 0)
      opacity 0
    &--header-content
      margin-top 16px
      margin-bottom 0
      color #24253D
      font-size 16px
      font-weight 500
      line-height 40px
    &--content
      max-width 320px
      color #24253D
      font-size 16px
      line-height 32px

  .flex-column-center
    display flex
    align-items center
    justify-content center
    flex-direction column
  .about-page-section-contact
    margin-top 135px
    background-color #F9FBFE
    display flex
    align-items center
    flex-direction column
    height 532px
    background-position center
    background-repeat no-repeat
    background-size: 100% 100%;
    &__icon
      background-image url('../../assets/images/ultimate_tool.svg')
      background-repeat no-repeat
      background-position 0 0
      transition all 1s
      width 48px
      height 48px
      margin 0 auto 36px
      transform scale(0)
    &--header
      max-width 455px
      text-align center
      font-size: 28px
      font-weight: 300
      line-height: 48px
    &--content
      margin-top 17px
      margin-bottom 40px
      text-align center
      font-size: 16px
      font-weight: 400
      line-height: 32px
    &--link
      text-decoration none
      color #4E77FF
      font-size 24px
      line-height 40px
    &__content
      display flex
      align-items center
      flex-direction column
      width 100%
      margin-top 120px
  .integration-page-footer
    &__container
      display flex
      justify-content center
      width 100%
      padding-top 96px
      padding-bottom 96px
      background-color: $blue
      transition all 1s
      opacity 0
    &__section
      width 100%
      display flex
      flex-direction column
      align-items center
    &--header
      margin-bottom 56px
      color: #ffffff
      text-align center
      font-size: 28px
      font-weight: 300
      line-height: 48px
    &--input
      width 430px !important
  .about-page-section-technology
    padding-top 129px
    padding-bottom 120px
    position: relative
    &--header
      max-width 570px
      color #24253D
      font-size 28px
      font-weight 300
      line-height 48px
    &__check-list
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      margin-top 36px
    &__check-item
      display flex
      align-items center
      margin-bottom 14px
    &--content
      margin 0 0 0 14px

    .section-conversion__bg
      display none
      @media screen and (min-width 768px)
        display block
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        background-image url('../../assets/images/sales-page_users-illustration.svg')
        background-repeat no-repeat
        background-position: center
        background-size 100% auto
        transform translate(0, 60%)
        transition all 1.5s cubic-bezier(0.39, 0.575, 0.565, 1)
        opacity 0
    &.a-on-enter
      .section-conversion__bg
        @media screen and (min-width 768px)
          transform translate(0, 0%)
          opacity 1
      .a-bottom-enter
        transform translate(0, 0%)
        opacity 1
  .about-page-section-vision
    margin-bottom 120px
    &__icon
      background-image url('../../assets/images/ultimate_tool.svg')
      background-repeat no-repeat
      background-position 0 0
      transition all 1s !important
      width 48px
      height 48px
      margin 0 auto 36px
      transform scale(0)
    &__paper
      transition all 1s cubic-bezier(0.39, 0.575, 0.565, 1) !important
      display flex
      align-items center
      flex-direction column
      padding 64px !important
    &--header
      margin-bottom 24px
      font-size 28px
      font-weight 300
      line-height 48px
      text-align center
    &--content
      max-width 615px
      font-size 16px
      line-height 32px
      text-align center
  .about-page-section-values
    background-color: #F9FBFE
    padding 120px 0 0
    &__values-section
      display flex
      flex-wrap wrap
      justify-content space-between
      align-items center
      max-width 800px
    &__values
      height: 289px
      width: 370px
      border-radius: 6px
      transition all 1s
      transform: translate(0, 91px)
      opacity 0
    &--values-img
      margin-bottom 36px
    &--values-header
      font-size 28px
      font-weight 300
      line-height 48px
    &--values-content
      font-size 16px
      line-height 32px
    &__paper
      max-width 370px
      padding 40px 36px  30px
      transition all 1.5s

      &:nth-child(2)
        transform: translate(0, 200px);
        opacity 0
        transition all 1.5s
      &:nth-child(3)
        transform: translate(0, 0)
        opacity 0
        transition all 1.5s
      &:nth-child(4)
        transform: translate(0, 200px)
        opacity 0
        transition all 1.5s
      &:nth-child(5)
        transform: translate(0, 0)
        opacity 0
        transition all 1.5s

    &--paper-img
      margin-bottom 35px
    &--paper-header
      color #24253D
      font-size 24px
      font-weight 500
      line-height 40px
    &--paper-content
      color #24253D
      font-size 16px
      line-height 32px
  .about-page-section-company
    margin-top 100px
    &__company-section
      min-width 780px
      min-height: 300px
      padding-top 110px
      padding-bottom 120px
      background url('../../assets/images/mapReferrer.svg') no-repeat right
    &--header
      padding-bottom 46px
      color: #24253D
      font-size: 28px
      font-weight: 300
      line-height: 48px
    &--content
      color #24253D
      font-size 16px
      line-height 32px
    &__list-company
      display flex
      flex-wrap wrap
      max-width 640px
      margin-left: -10px;
  &--animation
    transition transform 1s, opacity  1s
    transform translate(0, 20vh)
    opacity 0
  .a-on-enter
    .about-page-section-recruitment__section,
    .about-page-section-recruitment__content-left,
    .about-page-section-recruitment__content-right,
    .about-page-section--animation
    .integration-page-footer__container
      transform translate(0, 0)
      opacity 1
    .about-page-section-contact__icon,
    .about-page-section-vision__icon
      transform: scale(1)
    .about-page-section-values
      transition all 1.5s
      &__values
        transform translate(0, 0)
        opacity 1
        transition all .5s !important
      &__paper
        &:nth-child(2)
          transform: translate(0, 80px)
          opacity 1
        &:nth-child(3)
          transform: translate(0, -70px)
          opacity 1
        &:nth-child(4)
          transform: translate(0, 115px)
          opacity 1
        &:nth-child(5)
          transform: translate(0, -115px)
          opacity 1

  @media screen and (max-width 991px)
    margin-top 60px
    .about-page-section-recruitment
      margin-top: 80px
      margin-bottom: 80px
      &--header
        margin-bottom 50px
        text-align center
      &__content
        justify-content: center
        flex-direction: row
        width 100%
      &__content-right
        margin-top 0
    .about-page-section-contact
      margin-top 80px
      height: 480px;
      &__content
        margin-top 100px
  @media screen and (max-width 860px)
    .about-page-section-values
      padding 70px 0 0
      padding-bottom: 70px
      &__values-section
        justify-content: center
        flex-direction: column
      &__paper
        margin-bottom 28px

        &:nth-child(2)
          transform: translate(0, 80px)
          opacity 0
          transition all 1.5s
        &:nth-child(3)
          transform: translate(0, 80px)
          opacity 0
          transition all 1.5s
        &:nth-child(4)
          transform: translate(0, 80px)
          opacity 0
          transition all 1.5s
        &:nth-child(5)
          transform: translate(0, 80px)
          opacity 0
          transition all 1.5s
    .about-page-section-company
      margin-top 70px
      &__company-section
        min-width unset
        width 95%
        padding-top 0
        padding-bottom 50px
    .a-on-enter
      .about-page-section-values
        &__values-section
          justify-content: center
          flex-direction: column
        &__paper
          &:nth-child(2)
            transform: translate(0, 0);
          &:nth-child(3)
            transform: translate(0, 0)
          &:nth-child(4)
            transform: translate(0, 0)
          &:nth-child(5)
            transform: translate(0, 0)

  @media screen and (max-width 768px)
    .about-page-section-recruitment
      &__content-left
        margin-bottom 30px
      &__content-left-first
        margin-bottom 30px
      &__content
        flex-direction column
        align-items center
      &__content-right
        margin-left 0
    .about-page-section-technology
      width 96%
      margin-left auto
      margin-right auto
      padding-top: 90px
      padding-bottom: 90px
    .about-page-section-vision
      width 94%
      margin-left auto
      margin-right auto
    .about-page-section-vision
      &__paper
        padding 14px !important
      &__icon
        margin-bottom 20px
      &--header
        margin-bottom 0
    .about-page-section-contact
      &__content
        margin-top 70px

  @media screen and (max-width 640px)
    .integration-page-footer
      &__form
        width 90%
        display flex
      &--input
        width 60% !important
    .about-page-section-company
      &__company-section
        background-image none
      &--header
        padding-bottom 0
      &__list-company
        justify-content space-around

  @media screen and (max-width 480px)
    .about-page-section-recruitment
      &__content-right
        display flex
        flex-direction column
        align-items center
      &--plus
        display none
      &__paper:first-child
        margin-bottom 20px
    .integration-page-footer
      &__form
        width 100%
        justify-content: center
      &--input
        width 56% !important
      &--button
        min-width 40% !important
        width 40% !important
        button
          min-width 100% !important
          width 100% !important
        span
          padding-left 0 !important
          padding-right 0 !important
    .about-page-section-values
      padding 90px 0 0
      &__values
        width 320px
      &__paper
        width 320px
        padding 15px
