.refari-custom-background-modal
  padding: 24px 12px !important
  font-size: 16px !important
  background-color: #fff !important
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-width: 1060px!important;
  p 
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 15px;
  .refari-custom-background-template
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width 1100px)
      flex-wrap wrap
    &-left
      padding: 0 15px
      @media screen and (max-width 1100px)
        width: 100%;
        padding: 0 15px
    &-left-most
      max-width: 146px;
      max-height: 600px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0 6px;
      margin: 0 15px 0 0;
      &::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
        border-radius: 5px
        background-color: #F5F5F5
      &::-webkit-scrollbar
        width: 8px
        background-color: #F5F5F5
      &::-webkit-scrollbar-thumb
        border-radius: 6px
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
        background-color: #8E8E8E
      .refari-background-options
        margin: 0 !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        &-inner
          flex-direction: column;
          .refari-option-wrapper
            label
              width: 154px;
              max-height: 79px;
              overflow: hidden
    &-right
      max-height: 600px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 15px
      width: 100%;
      max-width: 670px;
      @media screen and (max-width 1100px)
        padding: 0 15px
        margin-top: 20px
        max-height: none !important;
        overflow: visible
      &-form
        margin-bottom: 10px;
        label
          color: #000000de
          font-weight 700
      &-additional-form
        p
          margin-bottom: 5px
          margin-top: 10px;
          font-weight 700
        .MuiFormControl-root.MuiFormControl-fullWidth
          margin-top: 5px
        .color-picker-wrap
          margin-bottom: 15px;
      &::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
        border-radius: 5px
        background-color: #F5F5F5
      &::-webkit-scrollbar
        width: 12px
        background-color: #F5F5F5
      &::-webkit-scrollbar-thumb
        border-radius: 6px
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
        background-color: #8E8E8E
  &-socialise
    max-width: 1440px !important;
    .refari-custom-background-template
      &-left
        padding: 0 15px 0 0;
      &-left-most
        max-width: 176px;
        min-width: 176px;
        max-height: 600px;
        overflow-y: scroll;
        overflow-x: hidden;
      &-right
        max-height: 600px;
        width: 100%;
        padding: 0 15px 0 5px;
        max-width: 670px;
    .MuiStepConnector-lineVertical
      border: none;
      min-height: 10px;
    .refari-modal-header
      padding-top: 15px !important
    .refari-card-header
      padding: 0 !important
  .field-tags-wrap
    margin-top: 0
  .MuiButton-label
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-weight: 500;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
  .refari-field-file-wrap
    .refari-file-input
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      opacity: 0;
  .MuiPaper-elevation1
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  .refari-card-image-holder
    min-height: 100px
  .refari-modal-header
    padding 15px 0
    font-size 24px
    padding-top: 30px !important
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    font-weight: 700 !important;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 36px;
  .refari-card-media__loader-wrapper
    display flex
    justify-content center
    align-items center
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    z-index 2000
    background-color rgba(255,255,255,.5)
  .refari-card-header
    padding 15px
  .refari-card-title-field
    font-weight 600
  .refari-card-description
    margin-bottom 15px
    line-height 22px
  .refari-card-description-field
    div
      font-size 14px!important
  .refari-field-file-wrap
    display inline-block
    margin-top: 15px;
    margin-bottom: 5px;
  .refari-card-subtitle
    max-width 380px
    display flex
    justify-content space-between
    align-items center
    margin-bottom 15px
    font-size 14px
  .refari-card-tags
    max-width 500px
    display flex
    justify-content space-between
    align-items center
    margin-bottom 15px
    font-size 14px
    padding-top 15px
    @media screen and (max-width 500px)
      flex-wrap wrap
  .refari-social-btn-wrapper
    display none !important
  .refari-direct-link-wrap
    padding 30px 15px
    .refari-copied-wrap
      max-width 300px
      margin 0 auto 30px

  .refari-color-slider
    padding 0 0 15px

.refari-direct-link-wrap
  &__copied-section
    display flex
    flex-direction column
    max-width 500px
    margin: 0 auto
    width 100%
  &--copied-header
    margin-top 24px !important
    margin-bottom 14px !important
    padding-bottom 0 !important

.refari-canvas-wrapper
  position: relative

.refari-socialize__first-step
  padding 0 2px
  .refari-socialize__radio-group
    margin: 10px 0;
    .refari-socialize__radio-buttons
      width: auto !important;
      display: inline-block !important;
      min-width: 180px
    .MuiFormControl-fullWidth
      margin: 0 !important
  .refari-first-step__hint
    font-size 14px
    margin-bottom 0
    line-height: 22px;
  .refari-description-counter
    font-size 12px
    line-height 18px
  .refari-additional-options-row
    font-size 14px
    display flex
    flex-wrap wrap
    margin-left 0 !important
    .MuiFormControl-fullWidth
      margin: 0 !important;
  .refari-background-options
    max-width 500px
    display flex
    margin 0 -2px 15px
    overflow-y auto
    overflow-x visible
    padding-bottom: 5px
    &.refari-gif-frames
      label
        transform scale(0.9)
        img
          box-shadow 0px 1px 7px 1px rgba(0,0,0,0.3)
        &:hover
          transform scale(1)
          img
            box-shadow none
      .refari-option-wrapper-selected
        label
          transform scale(1)
            img
              box-shadow none
    .refari-option-wrapper
      border 1px solid transparent
      position relative
      label.hasVideo
        &::before
          background-image: url("../../assets/images/playicon.svg")
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: center;
          background-color: #80808050;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
    label
      padding 2px
      width: 154px
      max-height: 79px
      overflow: hidden
      margin 0
      cursor pointer
      user-select none
      transition all 0.2s ease
      &:hover
        transform scale(0.9)
        img
          box-shadow 0px 1px 7px 1px rgba(0,0,0,0.3)
    img
      display inline-block
      width 100%
      user-select none
      transition all 0.2s ease
    &::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
      border-radius: 5px
      background-color: #F5F5F5
    &::-webkit-scrollbar
      width: 12px
      height: 12px
      background-color: #F5F5F5
    &::-webkit-scrollbar-thumb
      border-radius: 6px
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
      background-color: #8E8E8E
  .refari-background-options-testimonial
    max-height: 100px;
    display: flex;
    margin: 8px 0;
    position: relative;
    .slick-slider
      width: 100%;
      .slick-list
        margin-bottom: 15px;
    .refari-background-options-testimonial-shadows-left-shadow
      position: absolute;
      display: block;
      width: -moz-fit-content;
      width: fit-content;
      bottom: 0;
      top: 0;
      right: 0;
      z-index: 9;
      img 
        max-width: 50px;
        height: 100%;
    .refari-background-options-testimonial-shadows-right-shadow
      position: absolute;
      display: block;
      width: -moz-fit-content;
      width: fit-content;
      bottom: 0;
      top: 0;
      left: 0;
      z-index: 9;
      img 
        max-width: 50px;
        height: 100%;
    .refari-option-testimonial-wrapper
      border 1px solid transparent
      padding: 0 2px;
      img
        max-height: 100px;
    label
      padding 2px
      margin 0
      cursor pointer
      user-select none
      transition all 0.2s ease
      img
        box-shadow 0px 1px 2px 1px rgba(0,0,0,0.3)
      &.selected-background-image
        img 
          box-shadow: none
      &:hover
        transform scale(0.9)
        img
          box-shadow 0px 1px 7px 1px rgba(0,0,0,0.3)
    img
      display inline-block
      width 100%
      user-select none
      transition all 0.2s ease
  .refari-canvas-wrapper-testimonial
    .konvajs-content
      box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
  .refari-font-selector
    padding-top 10px
    margin-bottom 25px
    .MuiAutocomplete-endAdornment
      top: calc(50% - 22px);
    &__label
      font-size  14px
      span
        color rgba(0, 0, 0, 0.54)
        line-height 14px
    &__provider
      padding-top 15px
      font-size: 12px
      color rgba(0, 0, 0, 0.54)


.refari-socialize__second-step
  padding 10px 5px 15px
  .refari-social-btn-wrapper
    margin-bottom 40px
  .refari-socialize_networks
    margin-bottom 25px
    display flex
    flex-wrap wrap
    .refari-socialize_network
      display: flex
      flex 1
      width 33.333333% !important
      min-width 165px !important
      .refari-socialize_network__label
        display flex
        flex-wrap wrap
        span
          flex 1 0 100%
          font-size 12px
          display flex
          margin 5px 0 0px
          svg
            height 15px !important
            width 15px !important
      svg
        margin 0 5px
  .refari-socialize_companies
    margin-bottom 20px
  .refari-socialize__need-invite
    margin-bottom 15px
  .refari-direct-link-wrap
    padding 0 15px 15px
    h4
      padding-bottom 15px

.refari-socialise-editor
  &-panel
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: scroll;
  &-left-panel
    padding: 10px;
  &-tabs
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 500px;
    .MuiTab-root
      min-height: auto !important
      min-width: auto !important
      padding: 6px 12px !important;
      background-color: #fff;
      height: 36px;
    &_heading
      min-height: auto !important
  &-preview-heading
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    box-shadow: #0000000f 0px 1px 6px, #0000000f 0px 1px 4px
    &-title
      padding-left: 6px;
      font-size: 14px;
  &-top
    padding: 10px 10px 0 10px;
    &-inner
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      &-box
        padding-left: 10px;
        &-title
          font-size: 14px;
          margin-bottom: 0;
          line-height: 22px;
          color: #000000;
          font-weight: 700;
        &-response
          font-size: 12px;
          margin-bottom: 0;
          color: #7A7B7D;
          line-height: 20px;
      &-image
        position: relative;
        min-width: 60px;
    &-desc
      font-size: 14px;
      line-height: 20px;
      margin: 8px 0;
  &-bottom
    padding: 10px;
    &-title
      font-size: 14px;
      margin-bottom: 0;
      line-height: 20px;
      color: #000000;
      font-weight: 700;

.refari-socialise-preview
  max-height: 460px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 10px;
  padding-right: 6px;
  @media screen and (max-width 1100px)
    max-height: none !important;
    overflow: visible
  &::-webkit-scrollbar-track
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
    border-radius: 5px
    background-color: #F5F5F5
  &::-webkit-scrollbar
    width: 8px
    background-color: #F5F5F5
  &::-webkit-scrollbar-thumb
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
    background-color: #8E8E8E
  .refari-socialise-editor-top-inner-image
    min-width: auto;
  .refari-socialise-editor-top-inner-box-title
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span
      padding-left: 5px;
      color: #7A7B7D;
      font-size: 12px
  .refari-socialise-editor-top-inner-box-response
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg
      margin-left: 4px !important;
    span
      padding-left: 5px;
  .refari-socialise-editor-bottom
    background-color: #EEF3F8;
  .refari-socialise-editor-bottom-info
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    margin-bottom: 0;
    line-height: 20px;
    color: #7A7B7D;
    padding-top: 3px;
    span
      padding-left: 5px;
  .refari-socialise-editor-bottom-domain
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #7A7B7D;
    span
      padding-left: 5px;
  .refari-socialise-editor-bottom-desc
    font-size: 13px;
    margin-bottom: 0px;
    line-height: 20px;
    color: #7A7B7D;
  .refari-socialise-editor-action
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    border-top: 1px solid #ccc;
    margin: 10px;

@media screen and (max-width 792px)
  .refari-socialize__second-step
    .refari-social-btn-wrapper
      display block
    .refari-col
      padding 0
      margin-bottom 15px
    .refari-social-btn-section
      flex-wrap wrap
