*,
*::before,
*::after
  box-sizing border-box

html
  font-family sans-serif
  line-height 1.15
  -webkit-text-size-adjust 100%
  -ms-text-size-adjust 100%
  -ms-overflow-style scrollbar
  -webkit-tap-highlight-color transparent

@-ms-viewport
  width device-width

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section
  display block

body
  margin 0
  font-family "Roboto", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
  font-size 1rem
  font-weight 400
  color #2B2B2B
  text-align left
  background-color #fff

[tabindex="-1"]:focus
  outline none !important

hr
  box-sizing content-box
  height 0
  overflow visible

h1, h2, h3, h4, h5, h6
  margin-top 0

p
  margin-top 0
  margin-bottom 1rem

abbr[title],
abbr[data-original-title]
  text-decoration underline
  -webkit-text-decoration underline dotted
  text-decoration underline dotted
  cursor help
  border-bottom 0

address
  margin-bottom 1rem
  font-style normal
  line-height inherit

ol,
ul,
dl
  margin-top 0
  margin-bottom 1rem

ol ol,
ul ul,
ol ul,
ul ol
  margin-bottom 0

dt
  font-weight 700

dd
  margin-bottom .5rem
  margin-left 0

blockquote
  margin 0 0 1rem

dfn
  font-style italic

b,
strong
  font-weight bolder

small
  font-size 80%

sub,
sup
  position relative
  font-size 75%
  line-height 0
  vertical-align baseline

sub
  bottom -.25em

sup
  top -.5em

a
  color #007bff
  text-decoration none
  background-color transparent
  -webkit-text-decoration-skip objects
  &:hover
    color #0056b3
    text-decoration underline
  &:not([href]):not([tabindex])
    color inherit
    text-decoration none
  &:not([href]):not([tabindex]):focus
    outline 0

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover
  color inherit
  text-decoration none

pre,
code,
kbd,
samp
  font-family monospace, monospace
  font-size 1em

pre
  margin-top 0
  margin-bottom 1rem
  overflow auto
  -ms-overflow-style scrollbar

figure
  margin 0 0 1rem

img
  vertical-align middle
  border-style none

svg
  &:not(:root)
    overflow hidden

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea
  -ms-touch-action manipulation
  touch-action manipulation

table
  border-collapse collapse

caption
  padding-top 0.75rem
  padding-bottom 0.75rem
  color #868e96
  text-align left
  caption-side bottom

th
  text-align inherit

label
  display inline-block
  margin-bottom .5rem

button
  border-radius 0
  &:focus
    outline 1px dotted
    outline 5px auto -webkit-focus-ring-color

input,
button,
select,
optgroup,
textarea
  margin 0
  font-family inherit
  font-size inherit
  line-height inherit

button,
input
  overflow visible

button,
select
  text-transform none

button,
html [type="button"],
[type="reset"],
[type="submit"]
  -webkit-appearance button

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner
  padding 0
  border-style none

input[type="radio"],
input[type="checkbox"]
  box-sizing border-box
  padding 0

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"]
  -webkit-appearance listbox

textarea
  overflow auto
  resize vertical

fieldset
  min-width 0
  padding 0
  margin 0
  border 0

legend
  display block
  width 100%
  max-width 100%
  padding 0
  margin-bottom .5rem
  font-size 1.5rem
  line-height inherit
  color inherit
  white-space normal

progress
  vertical-align baseline

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button
  height auto

[type="search"]
  outline-offset -2px
  -webkit-appearance none

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration
  -webkit-appearance none

::-webkit-file-upload-button
  font inherit
  -webkit-appearance button

output
  display inline-block

summary
  display list-item

template
  display none

[hidden]
  display none !important

::-ms-clear,
::-ms-reveal
  width 0
  height 0

.inline-input-group
  .inline-input-text
    margin-bottom: 0;
    font-size: 12px;
    color: rgb(158, 158, 158);

.field-counter
  margin: 0
  color: rgb(158, 158, 158)
  font-size: 14px

.field-counter-flex
  display flex
  align-items center
  justify-content flex-end

.space-null
  margin 0
  padding 0

.field-counter-error
  color: red

.m_top_2x
  margin-top 24px

.m_top_3x
  margin-top 36px

.react-phone-number-input
  &__country-select
    display none !important
  &__country-select-arrow
    display none !important

.refari-video-wrapper
  position relative
  padding-bottom 53.25% /* 16:9 */
  padding-top 25px
  height 0

.consultant-add-button-heading
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h3
    margin-right: 10px


.refari-video-wrapper
  .refari-video-dimmer,
  iframe,
  object,
  embed
    position absolute
    top 0
    left 0
    width 100%
    height 100%
  .refari-video-dimmer
    z-index 2
    background-position center
    background-size cover

  .refari-play-button
    position absolute
    transform translate(-50%, -50%)
    left 50%
    top 50%

.react-tagsinput
  max-width 100%
  & > span
    flex-wrap wrap
    display flex
    align-items baseline
  .refari-react-tagsinput-tag-wrap
    margin-right 5px
    margin-bottom 5px
    max-width 100%
    .refari-react-tagsinput-tag
      max-width 100%
      & > span
        text-overflow ellipsis
        overflow hidden
      & > svg
        flex 1 0 24px
  .refari-react-tagsinput-input
    flex 1 0 0
    min-width 200px
  .refari-react-tags-select-input
    position relative
    flex 1 0 0
    min-width 100%
    width 100%
    &__modal
      position absolute
      left 0
      top 45px
      width 100%
      height auto
      max-height 130px
      overflow-y scroll !important
      z-index 1
      background-color #fff
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      .refari-filter-item
        padding  8px 56px 8px 50px !important
      .refari-nested
        padding-left 60px !important
    &--empty-modal
      padding 10px
      text-align 10px
      font-size 14px

.job-alert-modal
  label
    display unset
    margin-bottom: 0.5rem
    color: $black !important
  &--save-button
    margin-top 45px
    margin-bottom 30px

.left-space
  padding-left 222px !important

.modal
  .MuiDialog-paper
    padding: 24px
    color: rgba(60, 72, 86, 0.6)
    font-size: 16px
    max-width: 900px
  &-small
    .MuiDialog-paper
      max-width: 550px

.MuiDialog-paper
  overflow-x: hidden;

.leaderboard-badges-icon
  display: flex !important;
  flex-wrap: wrap;
  max-width: 180px;

.app
  .MuiSelect-select
    &:focus
      background-color: transparent;
  .MuiChip-deleteIconColorPrimary
    color: #fff
    &:hover, &:active
      color: rgba(255,255,255,0.5)
  .MuiPaper-rounded
    border-radius: 2px;
  .MuiPaper-elevation1
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  .MuiTableCell-head
    padding: 0 24px;
    height: 56px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
  .MuiTableCell-root
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  .MuiTable-root
    table-layout: fixed;

.MuiButton-containedPrimary
  &:focus, &:hover
    color: #fff;

.widgets-wrapper-main
  .MuiButton-contained
    background-color: #fff;

.refari-bottom-screen-ratings
  box-shadow: 0 -2px 5px 0 #00000033;
  left: 0;
  width: 100%;
  bottom: 0;
  font-size: 14px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: #fff;
  &-stars
    padding: 3px 10px 0;
    .widget-ratings
      padding-top: 1px;
  &-verified
    display: flex;
    align-items: center;
    justify-content: center;
    a
      padding-left: 8px;

.MuiFormControl-root.MuiFormControl-fullWidth
  margin-bottom: 5px;
  margin-top: 15px;

.Toastify__toast--success
  background-color: #27ae60 !important

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover
  color: rgb(177, 100, 84);

.blur-mask-rating
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0px;
  background-color: rgba(56, 134, 209, 0.2);
  backdrop-filter: blur(8px);

.blur-mask-modal-container, .blur-mask-modal-container-overlay
  margin-left: 224px !important;
  width: calc(100% - 224px) !important;

.modal-container-without-left, .modal-container-without-left-overlay
  margin-left: 224px !important;
  width: calc(100% - 224px) !important;

.black-color
 color: $black

@media screen and (max-width 991px)
  .left-space
    padding-left 0 !important
  .blur-mask-modal-container, .blur-mask-modal-container-overlay
    margin-left: auto !important;
    width: 100% !important;
    margin-top: 105px !important;
    height: calc(100% - 105px) !important;
  .modal-container-without-left, .modal-container-without-left-overlay
    margin-left: auto !important;
    width: 100% !important;

.ml-0
  margin-left: 0 !important;

.mt-1
  margin-top: 1rem !important;

.mt-0
  margin-top: 0 !important;

.ml-0
  margin-left: 0 !important;

.w-100
  width: 100% !important;

.w-50
  width: 50% !important;

.pt-0
  padding-top: 0 !important;

.pl-0
  padding-left: 0 !important;

.pr-0
  padding-right: 0 !important;

.line-height-1
  line-height: 1;

.text-transform-none
  text-transform: none !important;

.display-inline-flex
  display: inline-flex !important;

.iframe-dialog
  align-items: start !important;
  margin-top: @css{min(200px, 40vh)};

.iframe-dialog-center
  align-items: center !important;

.iframe-dialog-end
  align-items: end !important;
  margin-top: @css{max(-200px, -40vh)};

.MuiMenu-paper
    max-height: @css{min(800px, calc(100% - 96px))} !important

.refari-async-select__single-value,
.refari-async-select__placeholder {
    position: static;
    transform: none;
    max-width: none;
  }

.consultant-add-multiselect,
.MuiMenu-paper
    max-height: 370px !important

.checked-multiselect,
.MuiMenu-paper
    max-height: 370px !important

.table-sticky-column
  &::after
    content: "";
    border-right: 1px solid rgba(0,0,0,0.25);
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    
tr:hover
  .table-sticky-column
    background-color: #f0f0f0 !important
    opacity: 1

.refari-filter-item-container
  &:hover .bounce
    animation: bounce 1.5s ease infinite;


@keyframes bounce {
    70% { transform:translateY(0%); }
    80% { transform:translateY(-15%); }
    90% { transform:translateY(0%); }
    95% { transform:translateY(-7%); }
    97% { transform:translateY(0%); }
    99% { transform:translateY(-3%); }
    100% { transform:translateY(0); }
}


.loading_dots::after {
  content: '.';
  animation: dots 1.5s infinite steps(1);
}

@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '...';
  }
}
