.settings
  width 100%
  .settings-container
    max-width 780px
    margin 25px auto
  .settings-item
    margin 0 10px 25px 10px
    padding 20px 30px 50px 30px
    &:last-child
      margin-bottom 0
    h3
      margin-bottom 15px
    .settings-field
      margin-bottom 5px
      .MuiFormControl-root.MuiFormControl-fullWidth
        margin-bottom: 0px;
        margin-top: 0px;
    .settings-label
      padding-left 0 !important
      margin-top 15px
    .additional-email-section
      display flex
      justify-content flex-start
      align-items center
      flex-direction: row-reverse
      &__info
        flex-grow 0
      .settings-field
        flex-grow 1
        margin-top 12px
        margin-bottom 0
      &--img
        min-height 300px
      &__icon
        margin-left: 5px
        margin-top: 10px
    .profile-testimonials-section
      &--header
        margin-bottom 24px
        line-height: 24px
      &__author-info
        display flex
        justify-content space-between
        flex-wrap wrap
      &--author-info
        width 47%
      &--verified
        margin-bottom 7px
        font-style: normal
        font-weight: 500
        font-size: 13px
        line-height: 21px
        color: #27AE60
        a
          color: #27AE60
      &--author-info-date
        width 47% !important
        min-width 47% !important
        div
          width 100% !important
          min-width 100% !important
      &__container
        display flex
        max-width: 700px
        width: 100%
        overflow-y: auto
        padding-top: 18px
        &::-webkit-scrollbar-track
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
          border-radius: 10px
          background-color: #F5F5F5
        &::-webkit-scrollbar
          width: 12px
          height 6px
          background-color: #F5F5F5
        &::-webkit-scrollbar-thumb
          border-radius: 10px
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
          background-color: #797979
      &__item-section
        min-width: 275px
        width: 275px
        min-height: 320px
        margin-right 14px
      &__item
        position relative
        height: 340px
        padding 24px
        background: #F9FBFE
        .profile-testimonials-section--verified-show
          display inline-block
        .profile-testimonials-section--verified-hide
          display none
        &:hover
          .profile-testimonials-section--verified-show
            display none
          .profile-testimonials-section--verified-hide
            display inline-block
      &--edit
        margin-top: 8px
        text-align: center
        color: #3886D1
        cursor pointer
        font-size: 14px
        line-height: 18px
      &--item-content
        height: 196px
        margin-bottom 18px
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 21px
        color: #757575
        &-small
          height 156px
      &--item-name
        margin 0
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 21px
        color: #757575
      &--item-occupation
        margin 5px 0
        font-size: 12px
        line-height: 16px
        color: #757575
      &--item-date
        margin-bottom 0
        font-style: normal
        font-weight: normal
        font-size: 10px
        line-height: 14px
        color: #9E9E9E
  .field-group
    margin-bottom 30px
    &.field-group-hidden
      display none
    &:last-child
      margin-bottom 0

.agency-default-fonts
  width 100%

.reward-section
  &--header
    margin-bottom 14px
  &--toggle
     margin-top 14px
  &--input-field
    width 100% !important

.google-key-settings
   &--toggle
      margin-top 14px
   &__button-section
     display flex
     align-items center
     justify-content flex-end
   &--remove
      margin-left 10px !important
      background-color: #fff !important
.m-bot-1x
  margin-bottom 14px

.cropper-img-section
  &__wrap
    display flex
  &__wrap-vertical-layout
    flex-direction column
  &__button-wrap
    display flex
    align-content center
    flex-direction column
    padding-left 24px
  &__button-wrap-vertical-layout
    display flex
    flex-direction row
    align-content center
    justify-content center
  &__button-section
    display flex
    flex-wrap wrap
    justify-content space-around
    .warning-message
      width 100%
      color #ffa500
      margin-bottom 10px
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      svg
        color: #ffa500
        margin-right: 6px;
        width: 20px;
  &__save-button
    display flex
    .submit-btn
      width 170px
  &__button-section
    margin-top 25px

.reactEasyCrop_Container
  border: 1px solid grey;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC")

@media screen and (max-width 991px)
  .settings
    .settings-item
      .additional-email-section
        align-items flex-start

@media screen and (max-width 768px)
  .settings
    .settings-item
      .profile-testimonials-section
        &--author-info
          width 100%
        &--author-info-date
          width 100% !important
          min-width 100% !important

@media screen and (max-width 560px)
  .profile-form__tabs
    button
      font-size 12px !important
  .cropper-img-section
    &__wrap
      flex-direction column
    &__img-section
      display flex
      flex-direction column
      align-items center
      margin-bottom 10px
    &__button-wrap
      padding-left 0
    &__save-button
          .submit-btn
            width 100%

.additional-email-section
  &__img-section
    display: flex
    align-items: center
    justify-content: center
  &--img
    height 600px
  @media screen and (max-width 991px)
    &__img-section
      display block
      margin-top: -24px
      margin-left: -24px
      margin-right: -24px


.testimonials-section
  margin-bottom: 0
  margin-top: 36px
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 24px
  color rgb(158, 158, 158)

.profile-testimonials-section--quotes
  position: absolute
  top: -17px
  left: -5px

.margin-auto
  margin auto

.padding-2
  padding 2rem !important

.margin-left-1
  margin-left 1rem !important
