.card-field 
  position relative
  label + .MuiInput-formControl
    margin-top: 24px;
  .StripeElement__wrapper
    position relative
  .StripeElement
    width 100%
    min-height: 22px;
    
  .StripeElement__wrapper--focus + div hr:last-child
    transform scaleX(1) !important

  &__brand
    height 25px
    width 35px
    background-position center
    background-repeat no-repeat
    background-size cover
    position absolute
    right 0
    top 30px
