.floating-link-widget
    &__link
      position relative
      margin-bottom 24px
      &:hover
        .floating-link-widget--close
            opacity 1

    &--save-button
      margin-left 14px !important
    &--close
      position absolute !important
      top 10px
      right 10px
      opacity 0
      button
        width 28px !important
        height 28px !important
      svg
        width 20px !important
        height 28px !important