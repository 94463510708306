h1, h2, h3, h4, h5, h6
  font-weight 400
  margin 0
h1
  font-size 45px
  line-height 48px
h2
  font-size 36px
  line-height 54px
h3
  font-size 24px
  line-height 36px
h4
  font-size 18px
  line-height 27px
h5
  font-size 16px
  line-height 24px
h6
  font-size 14px
  line-height 21px
