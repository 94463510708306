.hashtag-section
  &--header-content
    font-weight: normal
    font-size: 14px
    line-height: 24px
    color: #757575

  .add-edit-hashtag
    display: flex
    &--stepper
      margin-right 24px
    &--tegs
      display flex
      align-items center
      justify-content center
      width 24px
      height 24px
      margin 0
      border-radius 50px
      color #fff
      font-style: normal
      font-weight: 500
      font-size: 12px
      line-height: 14px
    &--label-header
      margin 0
      font-style: normal
      font-weight: 500
      font-size: 16px
      line-height: 24px
      color: #757575
    &__video-section
       margin-top 29px
    &__loaded
      display: flex
      align-items: center
      margin-top 30px
      margin-bottom 15px
    &--loaded
      width 60px !important
      height 60px !important
      min-width 60px !important
      margin-right 14px
      margin-top 0 !important
      cursor: pointer
      .MuiButton-startIcon
        margin: 0 !important
      .MuiSvgIcon-root
        margin-top: 0 !important
    &--loaded-content
      margin 0
      color: #757575
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 24px
    &--or
      margin 0
      margin-bottom 20px
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 24px
      color: #757575
    &__input
      margin-top -15px
      max-width 352px
  .file-input
      cursor pointer
  .first-tag
    position relative
    margin-bottom 100px
    &:after
      content ''
      position: absolute
      top: 30px
      height 89px
      border-left 1px solid #d6d6d6
  .hashtag-item
    display flex
    margin-bottom 24px
    &__left-section
      margin-right 10px
    &__right-section
      display: flex
      justify-content: center
      flex-direction: column
    &--img
      width auto
      height auto
    &__video
      display flex
      align-items center
      justify-content center
      width 200px
      height 110px
      position relative
      z-index 2
      background-position center
      background-size cover
      cursor pointer
    &--name
      margin 0 0 5px 0
      font-style: normal
      font-weight: normal
      font-size: 24px
      line-height: 24px
      color: #757575
    &__edit-remove
      display flex
    &--edit
      cursor pointer
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 24px

