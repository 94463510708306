.pre-table
  min-height 78px
.table-wrap
  position relative
  box-shadow 0 1px 5px rgba(0, 0, 0, 0.13), 0 1px 2px rgba(0, 0, 0, 0.2)
  overflow hidden
  margin-bottom 25px

  .table-fake-scroll-container
    overflow-y hidden
    overflow-x auto
    height: 16px
    
    > div
      // see https://stackoverflow.com/a/35180602
      display: inline-block
      white-space: nowrap

  .table
    overflow-y hidden
    overflow-x auto
    tr.disabled
      opacity 0.5
    tr.bg-grey
      background-color: #F3F3F3
    tr.highlighted
      background-color #ffff008a
    .status-button
      margin-left: 15px !important;
      &.hover-status-button
        > div
          > div
            opacity: 1 !important
      > div
        > div
          background-color: #fff !important;
          color: #000 !important;
          border: 1px solid #ccc;
          padding: 10px !important;
          font-size: 12px !important;
          right: 40px !important;
          left: -250px !important;
          line-height: 20px !important;
          transform: translate(0px, 10px) !important;
          box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
          -webkit-box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
          -moz-box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
          > div
            background-color: #fff !important;
          span
            white-space: initial !important;
    .MuiTableCell-head
      width: 200px
      overflow: visible
.pagination-wrap
  margin-bottom 25px
  text-align center
.MuiPagination-ul
  justify-content: center;

.job-featured-modal
  .table-wrap .table
    min-width: auto;
    .MuiTableCell-head
      padding: 15px 12px 15px 24px;
      color: #fff
