.dashboard-report-container
    background-color: #F8F8F8
.dashboard-report
    margin: 20px 0;
    .toggle-button-group
        .MuiToggleButtonGroup-grouped
            border: 0;
            border-radius: 5px
            min-width: 110px;
            &.Mui-selected
                background-color: #fff;
            &:not(.Mui-selected)
                color: #7B7B7B !important;
    &-filter
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        &-type
            display: flex
            background-color: #EBEBEB !important
            flex-wrap: wrap
            max-width: 229px
            margin-right: 20px;
            border-radius: 5px !important
        &-date
            max-width: 480px;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .MuiFormHelperText-root
                margin: 0
        &-billing
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            &-value
                background-color: #EBEBEB !important;
                padding: 8px 12px;
                font-size: 14px;
                border-radius: 3px;
    .dashboard-chart-wrapper
        margin-top: 15px;
        h3
            letter-spacing: 0.48px;
            color: #2E364B;
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 2px;
        .dashboard-chart-data
            padding: 70px 15px 15px 15px;
            min-height: 354px;
            position: relative;
            .dashboard-chart-data-summary
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 10px #0000000D;
                border: 1px solid #F6F6F6;
                border-radius: 5px;
                opacity: 0.8;
                position: absolute;
                right: 10px
                top: 5px
                padding: 5px 10px