.refari-placements-wrapper
    .table
        width: 100%;
    .modal-content
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: .3rem;
        outline: 0;
    .modal-header
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #e9ecef;
        border-top-left-radius: .3rem;
        border-top-right-radius: .3rem;
    .modal-body
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
        &.crm-status-body
            padding: 0 1rem 1rem 1rem;
        .crm-status-no-data-found
            margin: 10px 0;
            text-align: center;
        .crm-status-list
            display: flex;
            list-style-type: none;
            margin: 0;
            padding: 0;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 20px;
            &-li
                flex: 0 1 40%;
                box-sizing: border-box;
                width: 100%;
                &-name
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    margin-top: 15px;
                    &-title
                        font-weight: bold;
                        font-size: 18px;
                        letter-spacing: 0.2px;
                    &-help
                        display: flex;
                        padding-left: 10px;
                        align-items: center;
                &-ul
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    &-li
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
    .modal-title
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0px;
        color: #404144;
        text-align: center;
        display: block;
        width: 100%;
        margin-bottom: 0;
    .modal-header .close
        padding: 1rem;
        margin: -1rem -1rem -1rem auto;
        border: 0;
        background: transparent;
    button.close
        position: absolute;
        display: block;
        right: 15px;
        top: 10px;
        z-index: 5;
        outline: none;
        font-size: 30px;
    .close:not(:disabled):not(.disabled)
        cursor: pointer;
    .setting-tab .nav
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    .setting-tab .nav-link
        display: block;
        padding: .5rem;
        text-decoration: none;
    .setting-tab ul li.nav-item
        width: 25%;
        text-align: center;
        cursor: pointer;
    .setting-tab ul li.nav-item .nav-link
        font-size: 14px;
        line-height: 30px;
        color: #404144;
        letter-spacing: 0.3px;
        border: 1px solid #ECECEC;
        border-radius: 0;
    .setting-tab ul li.nav-item .nav-link.active
        background-color: transparent;
        color: #3886D1;
        border-bottom: none;
        border-top: 2px solid #3886D1;
    .setting-title h5
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.3px;
        color: #404144;
        margin: 0;
    .setting-title h5 span
        color: #3886D1;
    .setting-title
        margin: 6px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    .table thead th
        vertical-align: middle;
        border-bottom: 1px solid #ECECEC;
        border-top: none;
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #9F9FA1;
        padding: 0 12px;
    .setting-cont .table input
        float: left;
        margin-top: 4px;
        margin-right: 16px;
        width: 20px;
        height: 20px;
        border-radius: 50% !important;
    .Donald
        position: relative;
        display: inline-block;
        width: 30px;
        margin-right: 10px;
    .Donald img
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    .Donald .img-fluid-circle  
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-block;
        background-color: #ccc;
        vertical-align: middle;
        border-style: none;
    .page-preload-spinner-table
        border-bottom: 0 !important;
        text-align: center !important;
    .hnt-img
        float: right;
        cursor: pointer;
    .pagination-wrap
        margin-top: 15px;
    .setting-cont .table td
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #2E364B;
        padding: 10px 10px;
        max-width: 150px;
    .setting-cont .table td label
        margin: 0;
    .setting-cont .table tr
        border-bottom: 1px solid #ECECEC;
    .setting-cont .table-responsive
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
    .setting-btn
        margin: 20px 0;
        text-align: center;
    .setting-btn .align-items-center
        padding-bottom: 20px
    .popover-body
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.28px;
        color: #757575;
        padding: 15px;
    .popover
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.14);
        border: none;
    button.close
        position: absolute;
        display: block;
        right: 15px;
        top: 10px;
        z-index: 5;
        outline: none;
        font-size: 30px;
    .setting-cont .table-responsive::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 0px;
        background-color: #F5F5F5;
    .setting-cont .table-responsive::-webkit-scrollbar
        width: 8px;
        background-color: #CFCFCF;
    .setting-cont .table-responsive::-webkit-scrollbar-thumb
        border-radius: 0px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #CFCFCF;
    .round
        position: relative;
        display: flex;
        align-items: center;
    .round.disabledCheckbox svg
        opacity: 0.5 !important
    .round > div
        height: 20px !important;
        width: 20px !important;

.modal-container-without-left
    .refari-modal
        > div
            border-radius: 5px !important
            > div
                overflow-x: hidden;
.tooltip-container
  background-color: white;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
  padding: 0.4rem;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  transition: opacity 0.3s;
  z-index: 2147483647;

.tooltip-arrow
  height: 1rem;
  position: absolute;
  width: 1rem;

.tooltip-arrow::before
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;

.tooltip-arrow::after
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;

.tooltip-arrow[data-placement*='bottom']
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;

.tooltip-arrow[data-placement*='bottom']::before
  border-color: transparent transparent silver transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;

.tooltip-arrow[data-placement*='bottom']::after
  border-color: transparent transparent white transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;

.tooltip-arrow[data-placement*='top']
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;

.tooltip-arrow[data-placement*='top']::before
  border-color: silver transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;

.tooltip-arrow[data-placement*='top']::after
  border-color: white transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;

.tooltip-arrow[data-placement*='right']
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;

.tooltip-arrow[data-placement*='right']::before
  border-color: transparent silver transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;

.tooltip-arrow[data-placement*='right']::after
  border-color: transparent white transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;

.tooltip-arrow[data-placement*='left']
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;

.tooltip-arrow[data-placement*='left']::before
  border-color: transparent transparent transparent silver;
  border-width: 0.5rem 0 0.5rem 0.4em;

.tooltip-arrow[data-placement*='left']::after
  border-color: transparent transparent transparent white;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;

.tooltip-body
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.28px;
    color: #757575;
    padding: 15px;
    max-width: 300px;

@media screen and (max-width: 480px)
    .refari-placements-wrapper
        .setting-cont .table-responsive
            overflow: auto;
.refari-phone-code-body
  padding: 0 4rem 2rem !important;
@media screen and (max-width: 480px)
    .refari-phone-code-body
        padding: 1rem 0 !important;
