.refari-pitch-me
  .custom-text-field
    hr
      display: none !important;
  &-top-block-head
    display: flex;
    align-items: center;
    justify-content: space-between;
  &-back-content
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    margin-top: 40px;
    justify-content: space-between;
    &-share
      display: flex
  &-head-title
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 25px;
    letter-spacing: 1.2px;
    font-size: 36px;
    line-height: 54px;
    font-family: inherit;
    padding-left: 5px;
  &-desc
    font-weight: normal;
    font-size: 15px;
    font-family: inherit;
    padding-left: 5px;
    margin-bottom: 0;
    margin-top: 0;
  &-divider
    border: none;
    color: transparent;
    background-color: transparent;
    padding: 35px 0 25px 0;
    &:before
      content: "";
      display: block;
      position: absolute;
      right: 0;
      max-width: 100%;
      width: 100%;
      border: 1px solid #cccccc;
  &-button-block
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 0;
    .refari-select-wrap
      margin-top: 5px !important
  &-button-block-fixed
    position: fixed;
  &-preview-loader
    margin-right: 15px;
    padding-top: 5px;
  &-create-loader
    margin-left: 15px;
    padding-top: 5px;
  &-heading
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &-title-desc
      flex-grow: 1;
    &-avatar
      margin-left: 15px;
      .refari-avatar-wrap
        max-width: 167px;
        max-height: 167px;
        border-radius: 0 !important;
        img
          border-radius: 0 !important;
    &-profile
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
      .MuiFormControlLabel-root
        margin-right: 0;
        margin-bottom: 0;
    &--title-input
      font-size: 36px;
      color: #757575;
      background-color: transparent;
      width: 100% !important
    &--desc-input
      margin-bottom: 0 !important;
      color: #757575;
      font-size: 16px;
      line-height: 24px;
      font-family: inherit;
    &--header-input
      padding: 10px 33px 10px 16px;
      font-size: 36px;
      color: #757575;
      background-color: transparent;
      border: 1px dashed #757575 !important;
      width: 100% !important
    &-closing-input, &-regards-input, &-description-input
      width: 100% !important;
      padding: 12px 16px !important;
      font-style: normal;
      font-size: 18px;
      color: #757575;
      border: 1px dashed #757575 !important;
      padding-right: 33px !important;
      margin-top: 0px !important;
      textarea
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        color: #757575 !important;
    .input-wrap
      position: relative;
      width: 100%;
      textarea
        background: transparent;
    .pencil-icon
      position: absolute;
      right: 7px;
      top: 0
    .m-t-2
      margin-top: 20px;
  &-block
    display: flex
    align-items: center;
    margin-bottom: 10px
    &-title
      flex: 1
      margin: 0;
      padding-left: 5px;
      &-help-input, &-expertise-input, &-job-input, &-client-input
        color: #000000;
        font-weight: 700;
        margin: 0;
        font-size: 23px;
        line-height: 36px;
        font-family: inherit;
        text-align: left;
        background: none;
        border: none
        width: 100%
      &-editor
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        align-items: center;
      svg
        margin-right: 10px
    &-toggle
      flex: 1
      text-align: right;
      .MuiFormControlLabel-root
        margin-right: 0;
        margin-bottom: 0;
  &-help
    margin-top: 50px;
  &-video-wrapper
    position: relative;
    width: 100%;
    height: 600px;
    iframe, .refari-video-dimmer
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    .refari-video-dimmer
      background-position: center;
      background-size: cover;
      &-change-link
        border: 1px dashed #757575;
    .refari-play-button, .refari-pitch-me-video-change-link-block
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      &-icon
        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        height: 120px;
        border-color: transparent transparent transparent #ffffff;
        transition: 100ms all ease;
        cursor: default;
        border-style: solid;
        border-width: 90px 0 90px 120px;
    .refari-pitch-me-video-change-link-block
      max-width: 500px;
      width: 90%;
  &-expertise
    margin-top: 50px;
    .react-tagsinput
      border: none !important
    .refari-react-tagsinput-input
      min-width: 100%
    &__search
      max-width: 400px;
    &-job-selected
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      svg
        color: #fff
    &-job-item
      margin-right: 10px !important;
      margin-bottom: 10px !important;
    &-item
      border: 10px;
      font-family: inherit;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      text-decoration: none;
      margin: 0px;
      margin-right: 10px;
      outline: none;
      font-size: 18px;
      font-weight: inherit;
      background-color: rgb(101, 112, 123);
      border-radius: 22px;
      color: #fff;
      width: fit-content;
      padding: 8px 20px !important;
  &-placed
    margin-top: 50px;
    &__search
      max-width: 400px;
    &-section
      margin-top: 25px;
    &-job-selected
      margin-top: 30px
      display: flex;
      flex-wrap: wrap;
    &-job-item
      border: 1px solid #ccc;
      border-radius: 10px;
      display: flex;
      width: fit-content;
      justify-content: space-between;
      padding: 10px 15px;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 15px;
      &-header
        margin: 0;
        line-height: 1.5;
        font-size: 15px;
        font-weight: bold;
      &-subtitle
        margin: 0;
        line-height: 1.5;
        font-size: 13px;
        font-weight: normal;
      &-close
        border: 0;
        background: 0;
        color: #9e9e9e;
        cursor: pointer;
        font-size: 25px;
        margin: 0;
        padding: 0;
        line-height: 0px;
        margin-left: 20px;
  &-testimonial
    &-section
      margin-top: 25px;
    &__search
      max-width: 400px;
    &-job-selected
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
    &-job-item
      position: relative;
      height: 396px;
      text-align: left;
      padding: 10px;
      width: 25%;
      &-inner
        padding: 23px;
        height: 330px;
      &-quotes
        position: absolute;
        top: 0;
        left: 0;
      &-content
        overflow: hidden;
        height: 160px;
        margin-bottom: 18px;
        margin-top: 0px;
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #757575;
      &-content-small
        height: 120px;
      &-star
        padding: 0;
        margin: 0;
        max-height: 30px;
      &__verified
        display: inline-block;
      &--verified-show
        display: inline-block;
      &--verified
        margin-bottom: 7px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: #27ae60;
        a
          color: #27ae60;
      &--verified-hide
        display: none;
      &:hover
        .refari-pitch-me-testimonial-job-item--verified-show
          display none
        .refari-pitch-me-testimonial-job-item--verified-hide
          display inline-block
      &-bottom-section
        position: absolute;
        bottom: 76px;
        width: calc(100% - 66px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      &-name
        margin: 0;
        margin-bottom: 5px;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #757575;
      &-occupation
        margin: 0;
        padding: 0;
        font-size: 12px;
        line-height: 16px;
        color: #757575;
      &-date
        margin-bottom: 3px;
        margin-top: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: #9e9e9e;
      &-close-section
        width: 100%
        text-align: center;
      &-close
        border: 0;
        background: 0;
        color: #9e9e9e;
        cursor: pointer;
        font-size: 25px;
        margin: 0;
        padding: 0;
        line-height: 0px;
        text-align: center
        margin-top: 10px
  &-regards
    width: 300px;
    margin-right: 30px;
    &-section
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 40px;
      flex-wrap: wrap;
      width: 100%
  &-call-to-action
    display: flex;
    width: calc(100% - 330px)
    &-button-block
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-left: 10px;
      margin-right: 10px;
    &-box
      min-height: 134px
      width: 100%
      position: relative
      overflow: hidden
      padding: 0
      &-inner
        display: flex;
        align-items: center;
    .react-draggable
      width: fit-content
  .auto-complete-component-item
    position relative
    padding 18px 32px
    border-bottom: 1px solid #ECEFF1
    cursor pointer
    p
      padding 0
      margin 0
    &--content
      font-size 14px
    &--plus-icon
      position absolute
      top 27%
      right 30px
      font-size 22px
    &:last-child
      border-bottom none
    &:hover
      background-color #ECEFF1

.refari-pitch-me-testimonial-job-item-content
  .refari-pitch-me-testimonial-job-item-content-read-more
    display: inline
    font-size: 14px
    text-decoration: underline

.refari-pitch-me-preview
  .refari-pitch-me-block
    margin-bottom: 20px
    &-title
      padding-left: 0px;
  .refari-pitch-me-expertise-section
    display: flex;
  .refari-pitch-me-testimonial-job-item
    height: 350px;
  .refari-pitch-me-testimonial-job-selected
    .refari-pitch-me-testimonial-job-item
      &:first-child
        padding-left: 0
      &:last-child
        padding-right: 0
  .refari-pitch-me-closing
    margin-bottom: 0 !important;
    margin-top: 40px;
    color: #757575;
    font-size: 18px;
    line-height: 24px;
    font-family: inherit;
  .refari-pitch-me-regards
    color: #757575;
    font-size: 22px;
    line-height: 30px;
    font-family: inherit;
    margin-top: 40px;
  .refari-pitch-me-call-to-action
    margin-top: 35px;

.no-drag-area
  position: absolute;
  user-select: none;
  width: 20px;
  height: 20px;
  right: -10px;
  bottom: -10px;

.refari-skeleton
  .refari-pitch-me-button-block
    display: flex;
    justify-content: flex-end
    > div
      margin-left: 20px
  .refari-pitch-me-block-title
    padding-left: 0px !important;
  .refari-pitch-me-expertise-skeleton 
    display: flex;
    margin-top: 20px;
    > div
      margin-right: 15px;

.link.refari-detail-back-link
  margin-bottom 15px
  display inline-flex

.refari-link.refari-detail-back-link
  margin-bottom 15px
  display inline-flex

.refari-detail-back-button
  background: transparent
  border: none
  display: flex
  align-items: center
  cursor: pointer
  margin-bottom: 20px
  color: $black
  &:hover
    .refari-detail-back-button--hover
      text-decoration underline
      color: $black
  &--hover
    margin-left: 5px

.refari-detail-top-header
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .refari-link.refari-detail-back-link
    margin-bottom: 0 !important
    margin-top: 0 !important
  &-right
    button
      @media screen and (max-width: 600px)
        margin-bottom: 10px
    &-pitch
      display: flex;
      align-items: center;
      justify-content: flex-end;

.refari-avatar-wrap
  width 240px
  height 240px
  margin 0 auto
  border-radius 50%
  overflow hidden
  display flex
  align-items center
  position relative
  &-not-round
    border-radius 0% !important
    & > img
      border-radius 0% !important
  & > img
    display block
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius 50%
  
@media screen and (max-width 1140px)
  .refari-pitch-me
    &-testimonial
      &-job-item
        width: 33%;

@media screen and (max-width 850px)
  .refari-pitch-me
    &-testimonial
      &-job-item
        width: 50%;

@media screen and (max-width 570px)
  .refari-pitch-me
    &-testimonial
      &-job-item
        width: 100%;