.badges
  width 100%
  margin-bottom 48px

.badges-container
.your-badges
  width 830px
  margin-left auto
  margin-right auto
  display: flex
  justify-content: space-between
  margin-top 26px

  &__left-section
    width: 450px;
  &__header
    display: flex;
    width 100%
    height: 60px;
    padding 17px 21px
    background-color: #3886D1
    color #fff
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  &--title
    text-transform capitalize
  &__content-item
    display flex
    flex-direction column
    align-items center
    justify-content center
  &__content-description
    text-align center
  &--content-header
    margin-top 16px
    margin-bottom 0
    color: #757575
    font-size: 12px
    font-weight: 400
    line-height: 16px
  &--content-description
    margin-bottom 0
    color: #757575
    font-size: 16px
    font-weight: 500
    line-height: 24px
  &--points
    margin-top 8px
    margin-bottom 0
    color: #616161
    font-size: 18px
    font-weight: 500
  &--points-count
    margin-bottom 0
    color: $primary;
    font-size: 36px;
    font-weight: 400;
  &__points-section
    display flex
    align-items center
    justify-content center
    flex-direction column
    width 360px
    margin-bottom 20px
    padding 16px
  &__loader-item
    display: grid;
    padding 16px 30px
    text-decoration: none
  &--loader-number
    color: #9e9e9e
    font-size: 12px
  &--loader-avatar
    width 32px
    height 32px
    margin-left 12px
    margin-right 12px
    overflow hidden
    border-radius 50%
  &--loader-name
    color: #757575
    font-size: 16px
    font-weight: 500
    line-height: 24px
  &--loader-points
    margin-bottom 0
    padding-left: 63px
    color #9e9e9e
    font-size 14px
    font-weight 400
    line-height 21px
  &__badge-icon-section
    display flex
    flex-wrap wrap
    max-width: 240px;
    margin-left: 70px
    .wrapper-points-section
      margin-bottom 5px
      cursor pointer

.all-badges
    width 770px
    margin-left auto
    margin-right auto
    &--header
      margin-top 16px
      color #616161
      font-size 24px
      font-weight 400
    &--content
      width 523px
      color #9e9e9e
      font-size 16px
      font-weight 400
      line-height 24px
    &__badges-group
      display flex
      .wrapper-points-section
        &:nth-child(2)
          transform: translate(-52px);
        &:nth-child(3)
          transform: translate(-104px);
        &:nth-child(4)
          transform: translate(-156px);
        &:nth-child(5)
          transform: translate(-209px);
    &--receive-data
      margin-left: auto
      color #9e9e9e
      font-size 14px
    &__roles
      display flex
      padding 36px 80px
      justify-content space-between
    &__roles-item
       margin-top 24px
    &--roles-header-img
      margin-left 7px
      margin-right 20px
    &__your-badges-list
      .all-badges__roles-list-item
        cursor pointer
        &:hover
          background-color #0000000a
    &__roles-list-item
      display flex
      padding 17px 21px
      &:nth-child(n + 2)
        border-top 1px solid #ECEFF1
    &--roles-img
      width 60%
    &--roles-counter
      font-size 12px
      margin-right 6px
      color #000
      text-align center
      margin-top 4px
    &__roles-list-description
      margin-left 6px
      display flex
      flex-direction column
      justify-content center
    &--roles-list-header
      margin unset
      color #757575
      font-size 16px
      font-weight 500
      line-height 24px
    &--roles-list-description
      margin unset
      color #9e9e9e
      font-size 14px
      font-weight 400
      line-height 21px
.wrap-points-section
  position relative
  margin-right 16px
  margin-bottom 8px
  &:hover
    .wrapper-points-section__description
      display inline-block
  &--min
    .close-wrapper
      position relative
      background-color: rgba(206, 206, 208, .3)
      &:after
        content: ""
        position: absolute
        right: -4px
        bottom: 0
        width: 13px
        height: 13px
        background-image url("../../assets/images/lock.svg")
        background-size: contain
        background-repeat: no-repeat

.wrapper-points-section
  display flex
  align-items center
  justify-content center
  position relative
  height 48px
  width 48px
  max-width 48px
  min-width 48px
  margin-right 10px
  border-radius 50%
  &__description
    display none
    position absolute
    bottom: -34px;
    max-width 100px
    padding 5px
    font-size 10px
    background-color rgba(0, 0, 0, .5)
    color #fff
    z-index: 99;

.wrapper-small
  height 30px
  width 30px
  max-width: unset
  min-width: 24px

.core-points-section
  display flex
  align-items center
  justify-content center
  height 83%
  width 83%
  border-radius 50%

.tin-wrapper
  background-color: #E1ECF8;

.tin-core
  background-color: #C3DAF1

.bronze-wrapper
  background-color: #FFD8A5;

.bronze-core
  background-color: #edb86e;

.silver-wrapper
  background-color: #E7E8EA;

.silver-core
  background-color: #ceced0;

.gold-wrapper
  background-color: #FBDB54;

.gold-core
  background-color: #debb2c;

.platinum-wrapper
  background-color: #BCC1C5;

.platinum-core
  background-color: #a2a7aa;

.close-wrapper
  position relative
  background-color: rgba(206, 206, 208, .3)
  &:after
    content: ''
    position absolute
    right: -4px;
    bottom: 3px;
    width 16px
    height:16px
    background-image url("../../assets/images/lock.svg")

.close-core
  background-color: rgba(206, 206, 208, .5)

@media screen and (max-width: 1124px)
  .badges-container
  .your-badges
    flex-direction column
    max-width 830px
    width 100%
    &__left-section
      width 100%
      margin-bottom 24px
    &__right-section
      width 100%
    &__badge-icon-section
      max-width 100%
    &__points-section
      width 100%
  .all-badges
    max-width 770px
    width 100%
    &__header
      height auto
    &--content
      max-width 523px
      width 100%
    &__roles-list-item
      padding 17px 20px
    &--roles-header-img
      width 26px
      height 26px

@media screen and (max-width: 768px)
  .your-badges
    &__header
      height auto
      padding: 17px 24px;
      p
        margin 0
    &__loader-item
      padding: 16px 26px;
      padding-bottom 0
      &:last-child
          padding-bottom 16px
  .all-badges
    &__roles-list-item
      padding: 17px 20px
    &__roles
      padding 30px
    &--roles-header-img
      width 26px
      height 26px

@media screen and (max-width: 560px)
  .all-badges
    &__roles
      flex-wrap wrap
      align-items center
      justify-content center
      padding-bottom 5px

      .your-badges__content-item
        margin-right 15px
        margin-bottom  20px
    &--receive-data
      text-align: right;
      width: 120px;
      min-width: 120px;

.leaderboard__badges-cell,
.all-badges__your-badges-list,
.yourProgress_leaderboard_badges,
.all-badges__roles-list-item_badge--center
  .MuiBadge-anchorOriginTopRightCircle
    top: 0
    right: 50%
