.container
  max-width 1200px
  width 100%
  padding 0 15px
  margin 0 auto

.full-container
  width 100%
  padding 0 15px
  margin 0 auto

.container-fluid
  max-width 100%
  width 100%
  padding 0 15px
  margin 0 auto

.space-between
  display flex
  justify-content space-between

.center
  display flex
  justify-content center

.space-around
  display flex
  justify-content space-around

.flex-end
  display flex
  justify-content flex-end

.align-items-center
  display flex
  align-items center

.align-items-flex-start
  display flex
  align-items flex-start

.align-items-flex-end
  display flex
  align-items flex-end

.align-items-baseline
  display flex
  align-items baseline

.align-center
  text-align center

.align-right
  text-align right

.align-block-right
  margin-left auto !important

.flex-wrap
  flex-wrap wrap

.position-absolute-center
  position fixed
  top 33%
  left 50%

.position-absolute-center-top
  position absolute
  top 22%
  left 50%

.flex-center
  display flex
  justify-content center

.flex-center-all
  display flex
  justify-content center
  align-items center

.flex-column
  flex-direction column

.m-r-1
  margin-right 14px !important

.m-r-2
  margin-right 24px !important

.m-r-2
  margin-right 34px !important

.m-l-1
  margin-left 14px !important

.m-l-2
  margin-left 24px !important

.m-l-2
  margin-left 34px !important

.m-t-1
  margin-top 14px !important

.m-t-2
  margin-top 24px !important

.m-t-3
  margin-top 34px !important

.m-b-1
  margin-bottom 14px !important

.m-b-2
  margin-bottom 24px !important

.m-b-3
  margin-bottom 34px !important

.m-b-4x
  margin-bottom 40px !important

.fl-1-3
    flex: 1 0 33.333333%
    max-width 100%
    @supports (flex-basis 33.333333%)
      flex-basis 33.333333%
      max-width 100%
.fl-2-3
  flex: 1 0 66.666667%
  max-width 100%
  @supports (flex-basis 33.333333%)
    flex-basis 66.666667%
    max-width 100%
