.billing 
  width 100%

.invoice-section
  a:not([href]):not([tabindex])
    cursor auto
  .table-row-col-item
    color #757575 !important

// put this into common styles
.border-color-grey
  border-color #e0e0e0 !important

.budget-settings
  width: 100%;
  max-width: 100%;
  margin: 25px auto;

  .title
    padding: 1rem;

  .recruiter-budget-detail
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;

