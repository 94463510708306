.communications-page
  .table
    tbody td:first-child a
      position relative
      padding-left 50px

      &:before
        content ''
        display block
        position absolute
        left 24px
        top 50%
        margin-top -4px
        border-radius 50%
        width 8px
        height 8px
        vertical-align middle

    .general td:first-child a:before
      background-color #4ab6fe
    .internal td:first-child a:before
      background-color #6d70ff
    .referrer td:first-child a:before
      background-color $hotPink
    .subscription td:first-child a:before
      background-color $teal
    .job td:first-child a:before
      background-color $orange
    .testimonials td:first-child a:before
      background-color $lime
    .registration td:first-child a:before
      background-color #008000
    .talent_board td:first-child a:before
      background-color #32012F
    .talent_alert td:first-child a:before
      background-color #52012F

  // Communication filter select menu items color encoding on the basis of data-value
  .checked-multiselect
    ul
      li
        &:before
          content ''
          display block
          position absolute
          right 44px
          top 50%
          margin-top -4px
          border-radius 50%
          width 8px
          height 8px
          vertical-align middle
          background-color #4ab6fe

        &[data-value="general"]:before
          background-color #4ab6fe
        &[data-value="internal"]:before
          background-color #6d70ff
        &[data-value="referrer"]:before
          background-color $hotPink
        &[data-value="subscription"]:before
          background-color $teal
        &[data-value="job"]:before
          background-color $orange
        &[data-value="testimonials"]:before
          background-color $lime
        &[data-value="registration"]:before
          background-color #008000
        &[data-value="talent_board"]:before
          background-color #32012F
        &[data-value="talent_alert"]:before
          background-color #52012F

.communication-subject
  .general
    background-color #4ab6fe
  .internal
    background-color #6d70ff
  .referrer
    background-color $hotPink
  .subscription
    background-color $teal
  .job
    background-color $orange
  .testimonials
    background-color $lime
  .registration
    background-color #008000
  .talent_board
    background-color #32012F
  .talent_alert
    background-color #52012F
  

.mail-preview__modal
  .mail-preview__content
    position relative
    padding 10px
    border-radius 2px
    box-shadow rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px
    margin-bottom 10px
    min-height 460px
    background-color rgba(0, 0, 0, 0.2)
    transition background-color 0.3s ease
    &.general
      background-color #4ab6fe
    &.internal
      background-color #6d70ff
    &.referrer
      background-color $hotPink
    &.subscription
      background-color $teal
    &.job
      background-color $orange
    &.testimonials
      background-color $lime
    &.registration
      background-color #008000

  .mail-preview__title
    margin 0 0 10px 0
    text-shadow 1px 1px 2px grey
    font-weight 700

  .mail-preview__body
    width 100%
    height 400px
    border 0
    overflow auto
    box-shadow rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px

  .mail-preview__receivers
    display flex
    flex-wrap wrap
    margin-bottom 6px
    > div
      text-overflow ellipsis
      max-width 680px
      overflow hidden
      margin 5px 5px 0 0!important

  .mail-preview__spinner
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    display flex
    justify-content center
    align-items center
    z-index 100

