.feedback-page
  .table-wrap
    .table
      tbody
        .review-star
          padding: 15px 12px 15px 24px;

.response-box
  border: 1px solid #ececec;
  padding: 10px 20px

.feedback-page-modal
  .excellent-box
    position: relative;
    display: block;
    width: 100%;
    background-color: #fcfcfc;
    border: 1px solid #ececec;
    .excellent-rating
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 20px;
      .review-star
        display: block;
        width: 70%;
        ul
          padding: 0;
          list-style: none;
          margin: 0;
          display: inline-block;
          li
            font-size: 22px;
            margin: 0 1px;
            position: relative;
            display: inline-block;
            width: fit-content;
            font-size: 26px;
            color: #ffc107;
            margin: 0 2px;
            &.by
              color: #d9d9d9;
              font-weight: 400;
              margin: 0 5px;
              font-size: 20px;
            &.Bruce
              color: #515151;
              font-weight: 500;
              font-size: 20px;
            &.range
              color: #35a28a;
              font-weight: 500;
              font-size: 18px;
            span
              font-size: 16px;
              color: #515151;
              font-weight: 500;
              letter-spacing: 0.5px;
              background-color: #f2f2f2;
              padding: 2px 10px;
              margin-left: 5px;
      .excelent-time
        display: block;
        width: 30%;
        text-align: right;
        p
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
          color: #9f9fa1;
          letter-spacing: 0.5px;
    .excelent-cont
      padding: 0 20px;
      h2
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        color: #515151;
        letter-spacing: 0.5px;
      p
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.5px;
        color: #828282;
        word-break: break-word;
        overflow: hidden
.feedback-page-toggle-publish
  padding: 0 20px;
  display: inline-block;
  text-align: left;

.feedback-page-comment
  padding: 0 20px;
  &-respond
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    color: #515151;
    svg
      width: 18px;
      height: 18px;
      margin-right: 5px;
    span
      text-transform: uppercase;
      font-size: 14px;
  &-response
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 8px;
    justify-content: space-between;
    color: #515151;
    font-size: 14px;
    &-public
      @media screen and (max-width 550px)
        width: 100%;
    &-private
      @media screen and (max-width 550px)
        width: 100%;
    &-box
      min-width: 370px;
      @media screen and (max-width 550px)
        min-width: auto;
      .MuiTextField-root
        border: 1px solid #ececec;
        padding: 0 10px;
        border-radius: 3px;
        background-color: #F6F6F5;
        margin-top: 5px;
      &-social
        .MuiIconButton-root
          padding: 5px;
    &-title
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-icon
        height: 20px;
        svg
          width: 20px;
          height: 20px;

.comment-box
  margin-bottom: 15px;
  &-inner
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
    &-box
      padding-left: 10px
      &-title
        color: #515151;
        font-weight: 500;
        font-size: 16px;
      &-response
        .MuiTextField-root
          border: 1px solid #ececec;
          padding: 0 10px;
          border-radius: 3px;
          background-color: #F6F6F5;
          margin-top: 5px;
  &-buttons
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

.modal-comment-box
  .comment-box-inner
    padding-left: 0
