
.slick-dots
  $default-button-color = #e1ecf8
  $active-button-color = $blue
  list-style none
  display flex !important
  justify-content center
  padding 10px 0
  button
    padding 0
    border none
    font inherit
    color inherit
    background-color transparent
    cursor pointer
    width 10px
    height 10px
    background-color $default-button-color
    border-radius 50%
    margin 0 6px
    line-height 0
    color transparent
    outline none
