.invoice

  &-wrapper
    margin 25px auto
    padding 20px 30px 50px
    box-shadow none !important
    &__header
      display flex
      align-items flex-start
      justify-content space-between
      color #9E9E9E
    &--darken
      color #757575
    &-popup-icon
      img
        cursor pointer
        margin-left 10px
        margin-bottom 3px
    &--color
      color #9E9E9E
    &--header-content
      margin-bottom 5px
    &--placements
      font-size 18px
  &__subtotal
    display flex
    flex-direction column
    align-items flex-end
  &__subtotal-item
    display flex
    justify-content space-between
  &--subtotal-item
    padding-right 10px
  .table-row-col-item
    color #757575 !important
    font-size: 14px;

  &__list-link
    margin-top 10px
  &__button-confirm
    display flex
    flex-wrap wrap
  h3
    margin-bottom 24px
  h4
    text-align center
    margin-bottom 20px
  .m-bot-05
    margin-bottom 5px !important
  &__total
    text-align right

  &__divider
    margin 20px 0 30px!important

  &-message
    margin-bottom 40px
    > div
      width 100%
      max-width 325px
  .pad-top-3x
    padding-top 30px
  .m-bot-1x
    margin-bottom 14px
  .table-wrap
    overflow auto
    .table
        > div
          overflow visible !important
  .table-header__title
    font-size 16px

.refari-invoice-breakdown-wrapper
  .setting-cont
    margin-bottom 20px

.header-with-icon
   position relative
   .comment-button
     padding: 0 !important
     margin: 0 !important
     width: 23px !important
     height: 23px !important
     position: absolute !important
     top: 0px !important
     right: -30px !important
     svg
       width 20px !important
       height 20px !important
       transform: rotate(180deg) !important

.pad_left_5p
  padding-left 5px

@media screen and (max-width 560px)
  .invoice
    &__button-section
      flex-direction: column-reverse
    &__actions, .review-pdf-button
       margin-top 10px
