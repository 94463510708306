.error
  width 100%
  min-height 570px
  .error-block
    max-width 350px
  .error-left
    display flex
    flex-direction column
    h1
      margin-bottom 35px
    h3
      font-weight 300
      margin-bottom 35px
    h4
      font-weight 300
  .error-right
    max-width 100%
  .static-link
    margin-top 30px
    
@media screen and (max-width 1023px)
  .error-left
    h1
      font-size 36px
    h4, h3
      font-size 16px
      line-height 24px
  .error-right
    display none
