
.request-modal
  h3
    font-weight 400 !important
    padding 20px 0 15px 0
    font-size 28px
  h3
    max-width 500px
    margin 0 auto
    text-align center
    padding-left 10px
    padding-right 10px
  .request-modal-content
    max-width 440px
    width 100%
    margin 0 auto
    .tel-input
       margin-bottom 22px