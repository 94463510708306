.password-hint
  font-size 12px
  font-style italic
  padding-top 5px
  overflow hidden
  transition all 0.3s ease-in
  &.expand
    max-height 500px
  &.collapse
    max-height 0px
  ul 
    padding-left 16px
    margin-bottom 0
  &-section
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  &-icon
    padding-left: 12px;