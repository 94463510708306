/*
 Display utilities following TailwindCSS convention
 Example https://tailwindcss.com/docs/display
*/

.flex-col
  flex-direction: column

.items-center
  align-items: center

.justify-center
    justify-content: center
