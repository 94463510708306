$default-link-color = #7f8197
$hover-link-color = darken($default-link-color, 20%)
$active-link-color = $blue
.sales-page-navigation
  display flex
  align-items center
  nav
    margin-right 25px
    @media screen and (max-width 1024px)
      display none
  .navigation-list
    margin 0
    padding 0
    list-style none
    display flex
  .navigation-item
    padding 19px 25px

  .navigation-link
    color $default-link-color
    &:hover
      color $hover-link-color
      text-decoration none
    &:active
      color $active-link-color
    @media screen and (min-width 1200px)
      font-size 16px

  .navigation__btn
    &:hover
      @media screen and (max-width 768px)
        background-color $active-link-color !important
      span
        color #fff !important
    span
      @media screen and (max-width 560px)
        font-size 10px !important

.header--sales
  .sales-page-navigation
    right 0
    transform translateX(0%)
    transition right 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)
    @media screen and (max-width 374px)
      padding-top 15px
  nav
    overflow hidden
    transition transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
               height 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)
    .navigation-list
      transition transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)
  &.is-minified
    .sales-page-navigation
      @media screen and (min-width 560px)
        position relative
        right 50%
        transform translateX(50%)
    nav
      transform scale(0.8)
      height 0px
      width 0px
      margin 0
      .navigation-list
        transform translateY(-50%)
    .navigation__btn
      @media screen and (max-width 560px)
        min-width 125px !important
      @media screen and (min-width 561px)
        min-width 150px !important





