.page-background
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index -1
  div
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    background-size 400% !important
    //animation colorSpin 30s linear infinite
    will-change all


@-webkit-keyframes colorSpin{
  25%{background-position:0 100%}
  50%{background-position:100% 100%}
  75%{background-position:100% 0}
  100%{
    -webkit-filter:hue-rotate(360deg)
    -moz-filter:hue-rotate(360deg)
    -o-filter:hue-rotate(360deg)
    filter:hue-rotate(360deg)
    -ms-filter:hue-rotate(360deg)
    }
}

@keyframes colorSpin{
  25%{background-position:0 100%}
  50%{background-position:100% 100%}
  75%{background-position:100% 0}
  100%{
    -webkit-filter:hue-rotate(360deg)
    -moz-filter:hue-rotate(360deg)
    -o-filter:hue-rotate(360deg)
    filter:hue-rotate(360deg)
    -ms-filter:hue-rotate(360deg)
    }
}
