.edm-builder-section
  &__title
    display flex
    align-items center
    justify-content space-between
    padding 30px
  &--title
    margin: 0
    font-style: normal
    font-weight: normal
    font-size: 24px
    line-height: 28px
    color: $silver
  &__header
    display flex
    width 100%
    position relative
    align-items center
    justify-content space-between
    padding 20px 36px
  &--header
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    color: #9E9E9E
  &--header-input
    padding: 10px 33px 10px 16px
    font-size: 36px
    color: #fff
    background-color: transparent
    border: 1px dashed #fff
  &--input
    padding: 12px 16px !important
    font-style: normal
    font-size: 18px
    color: $silver
    border: 1px dashed $silver !important
    padding-right: 33px !important
  &--white-input
    padding: 12px 36px 12px 16px
    font-style: normal
    font-size: 18px
    color: #fff
    background-color: transparent
    border: 1px dashed #fff
  &--input-min
    font-size: 14px
  &__header-img
    .file-wrap
      justify-content center
      .file-btn-wrap
        margin-top 14px
  &--img
    width 120px
    height 120px
    border-radius 50%
  .rdw-editor-main
    background-color #fff
    padding 14px
    height 130px
  &__header-editor
    width 75%
    .edm-builder-section--header-input, .edm-builder-section--input
       width 100%
  .dotted-border
    border 1px dotted
  .pad-1x
    padding 14px
  &__container
    padding 24px 36px
    .MuiFormControl-root.MuiFormControl-fullWidth
      margin-bottom: 0px;
      margin-top: 0px;
  .white
    ::placeholder
      color: #fff
    .error-text
      color: #fff !important
  .font-bold
    font-weight bold
  .input-wrap
    position relative
  .pencil-icon
    position: absolute
    right: 7px
  .error-text
    margin-top: 10px
    font-size: 14px
    margin-bottom: 0
    line-height: 14px
    color: #e53935
    text-align: right
    padding-right: 10px
  &--job-title
    font-style: normal
    font-weight: bold
    font-size: 14px
    color: #5C5C5C
    margin-bottom 20px
  &__search
     max-width 400px
     width 100%
  &__jobs-list
    display: flex
    flex-wrap wrap
    justify-content space-between
  &__jobs-list-empty
    display: flex
    align-items: center
    justify-content: center
  &__empty-job
    display: flex
    align-items: center
    justify-content: center
    width 100%
    height 328px
    min-width 300px
    max-width 49%
    border 1px dashed $silverSecond
    color: $silverSecond
  &--img-wrap
    display inline-block
    width 20px
    margin-right 5px
  &__job-item
    display: flex;
    flex-direction: column
    flex: 1 0 33.33333%
    margin-bottom: 30px
    padding 25px 30px
    width: 100%
    min-width: 300px
    max-width: 49%
    min-height: 328px
    position: relative
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px
  &--job-item-header
    margin-bottom 0
    padding-right: 3px
    cursor: pointer
    font-weight: bold
    font-size: 16px
    line-height: 24px
    color: $silver
  &--job-item-subtitle
    margin-bottom 0
    font-size: 14px
    color: rgba(0, 0, 0, 0.54)
    font-style: normal
    font-weight: normal
    line-height: 21px
    color: $silverSecond
  &--pointer
    cursor pointer
  &--job-item-content
    min-height 100px
    font-size: 14px
    padding: 10px 0
    margin: 0
    word-break: break-word
    font-family: inherit
    font-style: normal
    font-weight: normal
    line-height: 21px
    color: $silver
  &--job-item-reward
    margin-bottom 0
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 14px
    color: $silver
  &--job-item-close
    margin-bottom 0
    position absolute
    top 10px
    right 10px
    border 0
    background 0
    color $silverSecond
    cursor pointer
  &__job-item-icon
    margin-top 14px
  &--job-item-icon
    margin-bottom 0
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 22px
    color: $silver
  &__alert-create
    width: 100%
    display: flex
    align-items: center
    flex-direction: column
    margin-top 56px
  &--create-job-alert-btn
    background: #FFFFFF
    box-sizing: border-box
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)
    border-radius: 2px
    color: rgb(53, 162, 67)
    padding: 14px 26px
    text-transform: uppercase
    cursor: pointer
    font-size: 18px
  &__empty-alert
    margin-top 40px
    margin-bottom 30px
    border: 1px dashed #FFFFFF
    width: 200px
    height: 200px
    background-color: transparent
    cursor: pointer
    text-align: center
    color #fff
    .plus
      font-size: 40px
      line-height: 40px
  &__icon-section
    display flex
    align-items flex-start
    justify-content center
    flex-wrap wrap
    max-width 600px
    width 100%
  &__icon
    display: flex
    align-items: center
    justify-content: flex-start
    flex-direction: column
    position relative
    width: 33%
    min-height 150px
    margin-top 24px
  &--icon-text
    margin-bottom 0
    margin-top 14px
    max-height 90px
    color #fff
    text-align: center
    font-size 20px
    font-weight bold
    padding: 0 5px
    width: 100%
    overflow: hidden
  &--icon-btn
    border none
    background transparent
    width 30px
    height 30px
    color #fff
    cursor pointer
    text-align center
    font-size 24px
  .icon-btn-edit
    position: absolute
    right: 0
    top: -18px
  .icon-btn-remove
    position: absolute
    right: 0
    top: 16px
  &__sociale-links
    display: flex
    align-items: center
    justify-content: center
    padding: 14px 0
  &--sociale-links
    margin 0 14px
    border-radius 50%
    width: 70px
    height: 70px
    display flex
    align-items center
    justify-content center
  &--instagram
   background-color #6091B2
  &--facebook
    background-color #4A6CA7
  &--linkedin
    background-color #006CAC
  &--twitter
    background-color #00B9EF
  &__footer
    display flex
    align-items flex-end
    justify-content flex-end
    padding-bottom 30px
    padding-right 30px

.font-bold
  font-weight bold

.auto-complete-component-item
  position relative
  padding 18px 32px
  border-bottom: 1px solid #ECEFF1
  cursor pointer
  p
    padding 0
    margin 0
  &--content
     font-size 14px
  &--plus-icon
    position absolute
    top 27%
    right 30px
    font-size 22px
  &:last-child
    border-bottom none
  &:hover
    background-color #ECEFF1

@media screen and (max-width: 768px)
  .edm-builder-section
    &__header
      padding: 20px 15px
    &__header-editor
      width 100%
      margin-right: 10px
    &--header-input
      font-size 28px
    &__title
      padding: 15px
      display: flex
      flex-wrap: wrap
    &__container
      padding 24px 15px
    &--img
      width 70px
      height 70px
    &__jobs-list
      flex-direction column
    &__empty-job
      max-width 100%
    &__job-item
      max-width 100%
    &--sociale-links
      width: 50px
      height: 50px

.edm-preview-page
  max-width: 770px
  padding-bottom: 40px
  padding-top: 40px
  &--back
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 27px
    color: #757575
    text-decoration: unset
    cursor pointer
    display flex
    align-items: center
    width: 166px
    margin-bottom: 30px
    .icon-arrow
      margin-top 0
  .edm-builder__icon:hover
    color: #fff
    text-decoration: unset
  .edm-builder--create-job-alert-btn:hover
    text-decoration: unset
  .edm-builder--social-links
    display: flex
    align-items: center
    justify-content: center
  .edm-builder--social-links
    width: unset !important
    height: unset !important
  .edm-builder__job-item, .edm-builder__job-item-center
    width 45%
  .center-job
    display block
  .center
    flex-wrap wrap
  .edm-builder--job-item-content
    padding-bottom 10px

.option--is-disabled, .option--is-selected
  display none !important
