.centred-form
  max-width 340px
  display flex
  flex 1 1 340px
  flex-direction column
  justify-content center
  margin 25px auto
  padding 0 10px
  h2
    font-weight 700
    //word-break break-all

 .invited-user-form
   max-width 400px

@media screen and (max-width 768px)
  .invited-user-form
    max-width 340px
    h2
      overflow scroll
